import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("polygon", _mergeProps(_ctx.svgProps, {
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hover && _ctx.hover(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.unhover && _ctx.unhover(...args))),
    onMouseup: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)), ["left"])),
    class: { 'is-hovered': _ctx.active && _ctx.isHovered }
  }), [
    _createElementVNode("title", null, _toDisplayString(_ctx.element.name), 1)
  ], 16))
}