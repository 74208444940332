
import { corporaMixin } from '@/mixins'
import { defineComponent, PropType } from 'vue'
import { ElementDatasetSet } from '@/types/dataset'
import { mapState } from 'pinia'
import { useElementStore } from '@/stores'
import RemoveModal from '@/components/Dataset/RemoveModal.vue'
import { Element, ElementBase } from '@/types'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    RemoveModal
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    },
    elementSet: {
      type: Object as PropType<ElementDatasetSet>,
      required: true
    }
  },
  data: () => ({
    removeElementModal: false
  }),
  computed: {
    ...mapState(useElementStore, ['elementDatasetSets']),
    datasets (): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.element.id] ?? null
    },
    canRemove () {
      return (this.elementSet.dataset && this.elementSet.dataset.state === 'open' && this.elementSet.set && this.canWrite(this.corpus))
    },
    title () {
      if (!this.canWrite(this.corpus)) return 'You do not have the required rights to edit this dataset'
      else if (this.elementSet.dataset.state !== 'open') return 'Only open datasets can be edited'
      else return 'Remove element from this dataset set'
    }
  },
  methods: {
    removeElement () {
      if (!this.canRemove) return
      this.removeElementModal = true
    }
  }
})
