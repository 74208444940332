import { defineStore } from 'pinia'
import {
  ListCorpusMLClassesParameters,
  listCorpusMLClasses,
  createMLClass,
  updateMLClass,
  deleteMLClass,
  retrieveElement,
  validateClassification,
  rejectClassification,
  createClassification,
  ApiElementList,
  ApiElement
} from '@/api'
import { errorParser } from '@/helpers'
import { SEARCH_FILTER_MAX_TERMS } from '@/config'
import { UUID, MLClass, Classification, Element, ElementBase } from '@/types'
import { useNotificationStore } from '.'
import { isAxiosError } from 'axios'

interface CorpusMLClass extends MLClass {
  corpus_id: UUID
}

interface State {
  hasMLClasses: {
    [corpusUUID: UUID]: boolean
  }

  classes: {
    [id: UUID]: CorpusMLClass
  }

  count: {
    [corpusId: UUID]: number
  }

  classifications: {
    [elementId: UUID]: Classification[]
  }
}

function cleanElementList (this: State, elementList: ApiElement[]): Element[]
function cleanElementList (this: State, elementList: ApiElementList[]): ElementBase[]
function cleanElementList (this: State, elementList: (ApiElement | ApiElementList)[]): (Element | ElementBase)[] {
  const newClassifications: { [elementId: UUID]: Classification[] } = {}
  const newElementList = elementList.map(element => {
    const { classifications, ...newElement } = element
    if (classifications) newClassifications[newElement.id] = classifications
    return newElement
  })
  this.classifications = {
    ...this.classifications,
    ...newClassifications
  }
  return newElementList
}

function cleanElement (this: State & { cleanElementList: typeof cleanElementList }, element: ApiElement): Element
function cleanElement (this: State & { cleanElementList: typeof cleanElementList }, element: ApiElementList): ElementBase
function cleanElement (this: State & { cleanElementList: typeof cleanElementList }, element: ApiElement | ApiElementList): Element | ElementBase {
  return this.cleanElementList([element])[0]
}

export const useClassificationStore = defineStore('classification', {
  state: (): State => ({
    hasMLClasses: {},
    classes: {},
    count: {},
    classifications: {}
  }),
  actions: {
    add (elementId: UUID, classification: Classification) {
      if (!this.classifications[elementId]) {
        this.classifications[elementId] = [classification]
      } else {
        this.classifications[elementId].push(classification)
      }
    },

    update (elementId: UUID, classification: Classification) {
      if (!this.classifications[elementId]) throw new Error(`Cannot update classification ${classification.id}, element ${elementId} not found`)
      const index = this.classifications[elementId].findIndex(({ id }) => id === classification.id)
      if (index < 0) throw new Error(`Classification ${classification.id} not found in element ${elementId}`)
      this.classifications[elementId][index] = classification
    },

    remove (elementId: UUID, classificationId: UUID) {
      if (!this.classifications[elementId]) throw new Error(`Cannot remove classification ${classificationId}, element ${elementId} not found`)
      const index = this.classifications[elementId].findIndex(({ id }) => id === classificationId)
      if (index < 0) throw new Error(`Classification ${classificationId} not found in element ${elementId}`)
      this.classifications[elementId].splice(index, 1)
    },

    cleanElementList,
    cleanElement,

    async listCorpusMLClasses (corpusId: UUID, payload: ListCorpusMLClassesParameters = { page: 1 }) {
      try {
        if (payload && payload.search) {
          // Prevent 400 errors with too many search terms
          const words = [...new Set(payload.search.split(/\s+/))]
          if (words.length > SEARCH_FILTER_MAX_TERMS) {
            payload.search = words.slice(0, SEARCH_FILTER_MAX_TERMS).join(' ')
          }
        }

        const data = await listCorpusMLClasses(corpusId, payload)
        this.count[corpusId] = data.count

        if (!payload?.search) {
          this.hasMLClasses[corpusId] = data.count > 0
        }

        // Store ml classes in this store, indexed per id, with an additional corpus Id attribute
        data.results.map((mlClass: MLClass) => {
          const corpusMLClass: CorpusMLClass = { corpus_id: corpusId, ...mlClass }
          this.classes[mlClass.id] = corpusMLClass
          return corpusMLClass
        })

        return data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async listAllCorpusMLClasses (corpusId: UUID) {
      try {
        let page = 1
        /*
         * Keep asking for more pages for as long as the ML classes we have on this corpus do not reach the total count of classes
         * If we do not know how many classes there are, this assumes there is an infinity of classes, just so we do at least 1 API request.
         */
        while (Object.values(this.classes).filter((cl: CorpusMLClass) => cl.corpus_id === corpusId).length < (this.count[corpusId] ?? Infinity)) {
          const { next } = await this.listCorpusMLClasses(corpusId, { page })
          page++
          // The api indicates there is no next page: stop paginating immediately
          if (!next) break
        }
      } catch (err) {
        // Do nothing, just stop paginating: listCorpusMLClasses notifies of the error already
      }
    },

    async createCorpusMLClass (corpusId: UUID, payload: Omit<MLClass, 'id'>) {
      try {
        const data = await createMLClass(corpusId, payload)
        this.classes[data.id] = { corpus_id: corpusId, ...data }
        this.hasMLClasses[corpusId] = true
      } catch (err) {
        const parsedError = errorParser(err)
        if (parsedError.toLowerCase() === 'the fields name, corpus must make a unique set.') {
          useNotificationStore().notify({ type: 'error', text: `Class ${payload.name} already exists in this corpus.` })
          throw err
        } else {
          useNotificationStore().notify({ type: 'error', text: parsedError })
        }
      }
    },

    async editCorpusMLClass (corpusId: UUID, classId: UUID, payload: Partial<Omit<MLClass, 'id'>>) {
      try {
        const data = await updateMLClass(corpusId, classId, payload)
        this.classes[classId] = { ...this.classes[classId], ...data }
      } catch (err) {
        const parsedError = errorParser(err)
        if (parsedError.toLowerCase() === 'the fields name, corpus must make a unique set.') {
          useNotificationStore().notify({ type: 'error', text: `Class ${payload.name} already exists in this corpus.` })
        } else useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async deleteCorpusMLClass (corpusId: UUID, classId: UUID) {
      try {
        await deleteMLClass(corpusId, classId)
        delete this.classes[classId]
        this.count[corpusId] -= 1
        if (this.count[corpusId] <= 0) this.hasMLClasses[corpusId] = false
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async retrieveElementClassifications (elementId: UUID) {
      try {
        const data = await retrieveElement(elementId)
        this.classifications[elementId] = data.classifications
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async validate (classificationId: UUID, elementId: UUID) {
      try {
        const { data } = await validateClassification(classificationId)
        this.update(elementId, data)
        return data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async reject (classificationId: UUID, elementId: UUID) {
      try {
        const resp = await rejectClassification(classificationId)
        if (resp.status === 204) {
          // Classification was deleted on rejection
          this.remove(elementId, classificationId)
          useNotificationStore().notify({ type: 'info', text: 'Classification deleted due to rejection.' })
        } else {
          this.update(elementId, resp.data)
        }
        return resp
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async create (elementId: UUID, mlClassId: UUID) {
      try {
        if (!this.classes[mlClassId]) throw new Error(`Cannot create a classification. The class ${mlClassId} is not found.`)
        /**
         * The CreateClassification API endpoint returns a `worker_run_id`, whereas in the `classifications` returned when listing elements,
         * we have a `worker_run` (which is an id and a worker run summary). We cannot delete the worker_run_id property from
         * classificationCreation, as it is not optional in the ClassificationCreateResponse type, so instead we need to
         * destructure the returned value to have a sanitized createdClassification object.
         */
        const { worker_run_id, ...createdClassification } = await createClassification({
          ml_class: mlClassId,
          element: elementId
        })

        const classification: Classification = {
          ...createdClassification,
          ml_class: this.classes[mlClassId],
          worker_run: null
        }

        this.add(elementId, classification)
        return classification
      } catch (err) {
        if (isAxiosError(err) && err.response && err.response.status === 400 && err.response.data && err.response.data.non_field_errors) {
          throw new Error('This classification already exists on this element.')
        } else {
          throw err
        }
      }
    }
  }
})
