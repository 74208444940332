import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c641e06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-marginless" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "pb-1 is-marginless" }
const _hoisted_4 = { class: "pb-1" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkerRunSummary = _resolveComponent("WorkerRunSummary")!
  const _component_MetadataActions = _resolveComponent("MetadataActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.metadata.worker_run)
      ? (_openBlock(), _createBlock(_component_WorkerRunSummary, {
          key: 0,
          class: "is-pulled-right",
          "worker-run-details": _ctx.metadata.worker_run,
          "icon-only": ""
        }, null, 8, ["worker-run-details"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MetadataActions, _mergeProps({
      class: "pb-1 is-actions is-paddingless",
      "corpus-id": _ctx.corpusId,
      "element-id": _ctx.elementId,
      metadata: _ctx.metadata
    }, _ctx.$attrs), null, 16, ["corpus-id", "element-id", "metadata"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.metadata.name), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("blockquote", { innerHTML: _ctx.html }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}