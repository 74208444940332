
import { mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'

import { useMetaDataStore } from '@/stores'
import { UUID } from '@/types'
import { MetaData } from '@/types/metadata'

import Modal from '@/components/Modal.vue'

export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Modal
  },
  data: () => ({
    opened: true,
    loading: false
  }),
  computed: {
    ...mapStores(useMetaDataStore)
  },
  methods: {
    async performDelete () {
      if (this.loading) return
      this.loading = true
      try {
        await this.metadataStore.delete(this.elementId, this.metadata.id)
      } finally {
        this.loading = false
      }
    }
  }
})
