
import { defineComponent, PropType } from 'vue'
import { ElementDatasetSet } from '@/types/dataset'
import { mapState } from 'pinia'
import { useElementStore } from '@/stores'
import { Element, ElementBase } from '@/types'
import Row from './Row.vue'

export default defineComponent({
  components: {
    Row
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    }
  },
  computed: {
    ...mapState(useElementStore, ['elementDatasetSets']),
    datasetSets (): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.element.id] ?? null
    }
  }
})
