
import { mapStores } from 'pinia'

import { METADATA_TYPES } from '@/config'
import { corporaMixin } from '@/mixins'
import {
  useDisplayStore,
  useMetaDataStore
} from '@/stores'

import EditModal from './EditModal.vue'
import MarkdownMetadata from './Markdown.vue'
import StandardMetadata from './Metadata.vue'

import { defineComponent, PropType } from 'vue'
import { UUID } from '@/types'

/**
 * Displays all metadata for an element in the details panel.
 */
export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    EditModal,
    MarkdownMetadata,
    StandardMetadata
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    METADATA_TYPES,
    editModal: false
  }),
  computed: {
    ...mapStores(useDisplayStore, useMetaDataStore),
    elementMetadata () {
      return [...this.metadataStore.metadata[this.elementId] ?? []].sort((a, b) => a.name.localeCompare(b.name))
    },
    count () {
      return this.elementMetadata?.length
    },
    markdownMetadata () {
      return this.elementMetadata.filter(m => m.type === 'markdown')
    },
    standardMetadata () {
      return this.elementMetadata.filter(m => m.type !== 'markdown')
    },
    opened () {
      return this.displayStore.dropdowns.metadata ?? true
    }
  },
  methods: {
    fetchMetadata (elementId: UUID) {
      /*
       * This check does not use the computed properties because this might be called with a newValue from a watcher,
       * in which case it is not guaranteed that the computed properties are up to date.
       */
      if (!elementId || this.metadataStore.metadata[elementId]) return
      this.metadataStore.list(elementId)
    }
  },
  watch: {
    opened: {
      immediate: true,
      handler (newValue) {
        if (newValue) this.fetchMetadata(this.elementId)
      }
    },
    elementId: 'fetchMetadata'
  }
})
