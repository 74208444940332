
import { defineComponent, PropType } from 'vue'
import { mapActions } from 'pinia'
import { truncateMixin } from '@/mixins'
import { useNotificationStore } from '@/stores'

export default defineComponent({
  props: {
    itemId: {
      type: String as PropType<string | null>,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  mixins: [
    truncateMixin
  ],
  data: () => ({
    /**
     * Whether the UUID should be truncated or displayed fully.
     * It should be fully displayed when the user clicks on the ID.
     */
    truncated: true
  }),
  computed: {
    displayedId () {
      if (!this.itemId) return '—'
      if (this.truncated) return this.truncateId(this.itemId)
      return this.itemId
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async copyId () {
      if (!this.itemId) return
      this.truncated = false
      try {
        await navigator.clipboard.writeText(this.itemId)
        this.notify({ type: 'success', text: 'ID copied to clipboard' })
      } catch (err) {
        this.notify({ type: 'error', text: `Failed to copy ID '${this.itemId}': ${err}` })
      }
    }
  },
  watch: {
    /**
     * A change in the ID without re-mounting the component probably means
     * this component was reused by Vue or the router, so we reset the
     * truncated state as if we had just mounted the component.
     */
    itemId () {
      this.truncated = true
    }
  }
})
