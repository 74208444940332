
import { defineComponent, PropType } from 'vue'

import FIELDS from '@/components/Process/Workers/Configurations/ConfigurationForm/Fields'

import DropdownContent from '@/components/DropdownContent.vue'
import { UserConfigurationField } from '@/types/workerConfiguration'
import { cloneDeep, uniqueId } from 'lodash'
import { ConfigurationValidationError } from '@/helpers'
import { UUID } from '@/types'

export default defineComponent({
  components: { DropdownContent },
  emits: [
    'update:modelValue',
    'configuration-error'
  ],
  props: {
    schema: {
      type: Object as PropType<{[k: string]: UserConfigurationField} | null>,
      required: true
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true
    },
    modelValue: {
      type: Object as PropType<Record<string, unknown>>,
      required: true
    }
  },
  data: () => ({
    FIELDS,
    uid: uniqueId(),
    configuration: {} as Record<string, unknown>
  }),
  mounted () {
    this.configuration = cloneDeep(this.modelValue)
  },
  computed: {
    schemaRequired () {
      if (!this.schema) return null
      return Object.fromEntries(Object.entries(this.schema).filter(([, field]) => field.required))
    },
    schemaOptional () {
      if (!this.schema) return null
      return Object.fromEntries(Object.entries(this.schema).filter(([, field]) => !field.required))
    },
    configurationErrors () {
      if (!this.configuration || !Object.keys(this.configuration).length || !this.schema) {
        this.$emit('configuration-error', false)
        return {}
      }
      try {
        this.validateFields(this.configuration)
      } catch (e) {
        this.$emit('configuration-error', true)
        if (e instanceof ConfigurationValidationError) return e.errors
        throw e
      }
      this.$emit('configuration-error', false)
      return {}
    },
    defaultConfiguration () {
      if (!this.schema) return {}
      const filledForm: Record<string, unknown> = {}
      for (const property in this.schema) {
        filledForm[property] = this.schema[property].default ?? ''
      }
      return filledForm
    }
  },
  methods: {
    validateFields (config: Record<string, unknown>) {
      /*
       * Validates the configuration fields values against the schema; unexpected fields
       * (absent from the schema) are handled in JSONConfigError.
       */
      if (!this.schema) {
        this.$emit('update:modelValue', this.configuration)
        return
      }
      const errors = {} as Record<string, string>
      for (const [k, v] of Object.entries(config)) {
        const field = this.schema[k]
        if (!field) errors[k] = `Unrecognised field: ${k}`
        else if (!FIELDS[field.type]) {
          errors[k] = `Unknown field type: ${k}`
        } else {
          if (field.required === true) {
            /*
             * !v alone does not work to check that the value of a required field is not empty,
             * because !0 is true and 0 can be a valid value; !String(v).length returns false if
             * 0 (or 00000 etc) is entered, however it does not work to check against null,
             * which is why there is a second (v !== 0 && !v) check.
             */
            if (!String(v).length) errors[k] = 'This field is required.'
            else if (v !== 0 && !v) errors[k] = 'This field is required.'
          }
          if (v && (FIELDS[field.type].validate !== undefined)) {
            try {
              // @ts-expect-error Each validation function only accepts the UserConfigurationField of their own type, but TS does not understand the intersection of all fields
              config[k] = FIELDS[field.type].validate(v, field)
              delete errors[k]
            } catch (e) {
              if (e instanceof Error) errors[k] = e.message
            }
          }
        }
      }
      if (Object.keys(errors).length > 0) throw new ConfigurationValidationError(errors)
      else this.$emit('update:modelValue', this.configuration)
    }
  }
})
