import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    title: 'original date: ' + _ctx.rawDate
  }, [
    (_ctx.lower && _ctx.upper)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" From " + _toDisplayString(_ctx.display(_ctx.lower)) + " to " + _toDisplayString(_ctx.display(_ctx.upper)), 1)
        ], 64))
      : (_ctx.lower)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(" After " + _toDisplayString(_ctx.display(_ctx.lower)), 1)
          ], 64))
        : (_ctx.upper)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(" Before " + _toDisplayString(_ctx.display(_ctx.upper)), 1)
            ], 64))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.dates, (date, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, [
                _createTextVNode(_toDisplayString(_ctx.display(date)) + " ", 1),
                (_ctx.dates.length > 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" [" + _toDisplayString(date.type) + "]", 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
  ], 8, _hoisted_1))
}