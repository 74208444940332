
import { mapState, mapActions } from 'pinia'
import DropdownContent from '@/components/DropdownContent.vue'
import { useElementStore } from '@/stores'
import { PropType, defineComponent } from 'vue'
import { UUID, Element, ElementBase } from '@/types'

export default defineComponent({
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  components: {
    DropdownContent
  },
  data: () => ({
    allowedRotations: [0, 90, 180, 270],
    rotationAngle: 0,
    mirrored: false,
    loading: false
  }),
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useElementStore, { canWriteElement: 'canWrite' }),
    element (): Element | ElementBase {
      return this.elements[this.elementId]
    }
  },
  methods: {
    ...mapActions(useElementStore, ['patch']),
    toggleMirrored () {
      if (!this.loading) this.mirrored = !this.mirrored
    },
    async updateElement () {
      /*
       * When the component is loaded, when the element changes while browsing between neighbors
       * or when the element is loaded after the component, a watcher updates this component's fields
       * to keep them up-to-date with the element.
       * This update can cause updateElement to trigger, and that could cause unnecessary PATCH requests
       * to update the element's attributes to the ones it already has.
       * If the user does not have write access, this could also cause 403 errors.
       * We prevent this by not allowing any PATCH requests if the element's attributes are the same as our fields.
       */
      if (this.loading || (this.rotationAngle === this.element.rotation_angle && this.mirrored === this.element.mirrored)) return
      this.loading = true
      try {
        await this.patch(
          this.elementId,
          {
            rotation_angle: this.rotationAngle,
            mirrored: this.mirrored
          }
        )
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    rotationAngle: 'updateElement',
    mirrored: 'updateElement',
    element: {
      handler (newValue) {
        if (!newValue) return
        this.rotationAngle = newValue.rotation_angle
        this.mirrored = newValue.mirrored
      },
      immediate: true
    }
  }
})
