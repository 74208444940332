
import { mapActions } from 'pinia'
import { errorParser } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import Modal from '@/components/Modal.vue'
import { useElementStore, useNotificationStore } from '@/stores'
import { defineComponent } from 'vue'
import { ElementType } from '@/types'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    Modal
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    corpusId: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false,
    typeSlug: '',
    name: ''
  }),
  computed: {
    availableTypes (): ElementType[] {
      return this.corpus ? this.folderTypes(this.corpus.types) : []
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async addFolder () {
      if (this.loading || !this.canWrite(this.corpus) || !this.typeSlug || !this.name) return
      this.loading = true
      try {
        const folder = await useElementStore().create({
          corpus: this.corpusId,
          parent: this.parentId,
          type: this.typeSlug,
          name: this.name
        })
        // Display a notification with a link to the folder
        const type = this.availableTypes.find(t => t.slug === this.typeSlug)
        this.notify({
          type: 'success',
          text: 'Successfully created',
          link: {
            text: `${(type && type.display_name.toLowerCase()) || this.typeSlug} "${this.name}"`,
            route: { name: 'element-details', params: { id: folder.id } }
          }
        })
      } catch (e) {
        this.notify({ type: 'error', text: `An error occurred while creating the folder: ${errorParser(e)}` })
      } finally {
        this.loading = false
        this.$emit('update:modelValue', false)
      }
    }
  },
  watch: {
    availableTypes (newTypes: ElementType[]) {
      // Unselect any non-existent type
      if (!newTypes.some(t => t.slug === this.typeSlug)) this.typeSlug = ''
      // Autoselect when there is only one folder type
      if (newTypes.length === 1 && this.typeSlug === '') this.typeSlug = newTypes[0].slug
    }
  }
})
