
import { PROCESS_STATES, PROCESS_STATE_COLORS } from '@/config'
import { ProcessState } from '@/types/process'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    state: {
      type: String as PropType<ProcessState | undefined>,
      required: true,
      validator: value => typeof value === 'string' && value in PROCESS_STATES
    }
  },
  computed: {
    color (): string {
      if (!this.state) return PROCESS_STATE_COLORS.default.cssClass
      return PROCESS_STATE_COLORS[this.state].cssClass
    },
    displayName () {
      if (!this.state) return 'Unknown'
      return PROCESS_STATES[this.state]
    }
  }
})
