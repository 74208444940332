
import { mapState, mapActions } from 'pinia'
import { corporaMixin, truncateMixin } from '@/mixins'
import ElementImage from '@/components/Image/ElementImage.vue'

import { defineComponent, PropType } from 'vue'
import { useElementStore } from '@/stores'
import { UUID, Element, ElementBase } from '@/types'

export default defineComponent({
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    ElementImage
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  data: () => ({
    hovered: false
  }),
  computed: {
    ...mapState(useElementStore, ['elements']),
    element (): Element | ElementBase {
      return this.elements[this.elementId]
    },
    corpusId (): UUID | null {
      if (this.element.corpus === null) return null
      return this.element.corpus.id
    }
  },
  methods: {
    ...mapActions(useElementStore, { getElement: 'get' }),
    /**
     * Make a GET request to retrieve the element if the modal is opened and
     * if the element's zone is not available.
     */
    fetchElement () {
      if (!this.hovered || this.element?.zone !== undefined) return
      this.getElement(this.elementId)
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler: 'fetchElement'
    },
    hovered: 'fetchElement'
  }
})
