<template>
  <div class="column is-narrow is-flex-shrink-1 p-0">
    <!-- Project actions -->
    <template v-if="!elementId">
      <div class="dropdown is-right is-hoverable">
        <div class="dropdown-trigger">
          <a class="navbar-link">
            Project
          </a>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <!-- Edit button for writable projects -->
            <component
              :is="hasContribPrivilege ? 'router-link' : 'a'"
              class="dropdown-item"
              :class="!hasAdminPrivilege ? 'is-disabled' : ''"
              :to="hasAdminPrivilege ? { name: 'corpus-update', params: { corpusId } } : ''"
              :title="hasAdminPrivilege ? 'Edit this project\'s information.' : executeDisabledTitle"
            >
              <i class="icon-edit"></i>
              Project information
            </component>
            <!-- Delete button for projects with admin rights -->
            <DeleteModal :target="corpus">
              <template v-slot:default="{ open }">
                <a
                  :class="{ 'is-disabled': !hasAdminPrivilege }"
                  class="dropdown-item has-text-danger"
                  v-on:click="open"
                  :title="hasAdminPrivilege ? 'Delete this project.' : executeDisabledTitle"
                >
                  <i class="icon-trash"></i>
                  Delete the current project
                </a>
              </template>
            </DeleteModal>
          </div>
        </div>
      </div>
    </template>

    <!-- Elements actions -->
    <div class="dropdown is-right is-hoverable">
      <div class="dropdown-trigger">
        <a class="navbar-link">
          Elements
        </a>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <!-- Add folder is disabled but visible if the corpus does not have a folder type -->
          <a
            v-if="canContain"
            class="dropdown-item"
            :class="(!hasContribPrivilege || !hasFolderTypes) ? 'is-disabled' : ''"
            v-on:click="addFolderModal = hasContribPrivilege && hasFolderTypes"
            :title="hasContribPrivilege && hasFolderTypes ? 'Create a folder on this directory.' : createDisabledTitle"
          >
            <i class="icon-add-square"></i>
            Add folder
          </a>
          <template v-if="isVerified && hasFeature('selection')">
            <!-- Select/Unselect button for elements -->
            <a
              v-if="element"
              class="dropdown-item"
              :class="selected ? 'has-text-danger' : 'has-text-info'"
              v-on:click="select"
            >
              <i :class="selected ? 'icon-minus' : 'icon-plus'"></i>
              {{ selected ? 'Remove from selection' : 'Add to selection' }}
            </a>
            <a
              v-if="canContain"
              class="dropdown-item has-text-info"
              title="Not all child elements are selected, only the elements displayed on this page"
              v-on:click="selectAll"
              :class="!canSelectAll ? 'is-disabled' : ''"
            >
              <i class="icon-plus"></i>
              Select all displayed elements
            </a>
          </template>
          <!-- Move button for elements with write rights -->
          <a
            v-if="isVerified && element"
            class="dropdown-item"
            :class="!hasContribPrivilege ? 'is-disabled' : ''"
            :disabled="!hasContribPrivilege || null"
            v-on:click.prevent="moveModal = true"
            :title="hasContribPrivilege ? 'Move the current element to another folder.' : createDisabledTitle"
          >
            <i class="icon-direction"></i>
            Move the current element
          </a>
          <!-- New parent element link for elements with write rights -->
          <a
            v-if="isVerified && element"
            class="dropdown-item"
            :class="!hasContribPrivilege ? 'is-disabled' : ''"
            v-on:click.prevent="createParentModal = true"
            :title="hasContribPrivilege ? 'Link the current element to another folder.' : createDisabledTitle"
          >
            <i class="icon-direction"></i>
            Link to another parent
          </a>
          <!-- Delete button for listed elements in projects and folders -->
          <a
            v-if="canContain"
            class="dropdown-item has-text-danger"
            :class="!canDeleteFiltered ? 'is-disabled' : ''"
            :title="deleteFilteredTitle"
            v-on:click="deleteFilteredModal = canDeleteFiltered"
          >
            <i class="icon-trash"></i>
            Delete filtered elements
          </a>
          <!-- Delete button for elements -->
          <DeleteModal v-if="element" :target="element" redirect>
            <template v-slot:default="{ open }">
              <a
                :class="{ 'is-disabled': !hasAdminPrivilege }"
                class="dropdown-item has-text-danger"
                v-on:click="open"
                :title="hasAdminPrivilege ? 'Delete this element.' : executeDisabledTitle"
              >
                <i class="icon-trash"></i>
                Delete the current element
              </a>
            </template>
          </DeleteModal>
        </div>
      </div>
    </div>

    <!-- Import and export actions -->
    <template v-if="canContain">
      <div class="dropdown is-right is-hoverable">
        <div class="dropdown-trigger">
          <a class="navbar-link">
            Import / Export
          </a>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <component
              :is="hasContribPrivilege ? 'router-link' : 'a'"
              class="dropdown-item"
              :class="(!hasContribPrivilege || !hasFolderTypes) ? 'is-disabled' : ''"
              :to="hasContribPrivilege ? { name: 'process-files', params: { corpusId, folderId: elementId } } : ''"
              :title="hasContribPrivilege ? 'Import files in a new folder.' : createDisabledTitle"
            >
              <i class="icon-doc"></i>
              Import files
            </component>
            <component
              v-if="hasFeature('ingest')"
              :is="canImportFromS3 ? 'router-link' : 'a'"
              class="dropdown-item"
              :class="{ 'is-disabled': !canImportFromS3 }"
              :to="canImportFromS3 ? { name: 'process-buckets', params: { corpusId, folderId: elementId } } : ''"
              :title="S3ImportTitle"
            >
              <i class="icon-doc"></i>
              Import files from S3
            </component>
            <!-- Exports modal for verified users on all projects -->
            <a class="dropdown-item" v-if="isVerified" v-on:click="exportsModal = true">
              <i class="icon-export"></i>
              Manage database exports
            </a>
            <!-- Export process modals for each available export mode -->
            <ExportProcessModal
              v-for="mode in Object.keys(EXPORT_FORMATS)"
              :format="mode"
              :corpus-id="corpusId"
              :element-id="element?.id"
              :key="mode"
            >
              <template v-slot:open="{ open }">
                <a
                  :class="{ 'is-disabled': !hasAdminPrivilege }"
                  class="dropdown-item"
                  v-on:click="open"
                >
                  <i class="icon-export"></i>
                  Export as {{ EXPORT_FORMATS[mode].label }}
                </a>
              </template>
            </ExportProcessModal>
            <!--
              IIIF manifest for folder elements, with viewers in public corpora
              We have to ensure the hrefs are `undefined` to make the <a> tags not link to anything
              when they are clicked; `undefined` removes the attribute entirely.
              href="" would cause a refresh and href="#" can affect the router.
              The `disabled` does not officially exist on <a> tags, so it would not affect them.
            -->
            <template v-if="elementType?.folder">
              <a
                v-if="miradorUri(elementId)"
                class="dropdown-item"
                :href="corpus.public ? miradorUri(elementId) : undefined"
                :title="corpus.public ? undefined : 'This feature is only available in public projects.'"
                :class="!corpus.public ? 'is-disabled' : ''"
                target="_blank"
              >
                <i class="icon-eye"></i>
                View in Mirador
              </a>
              <a
                v-if="uvUri(elementId)"
                class="dropdown-item"
                :href="corpus.public ? uvUri(elementId) : undefined"
                :title="corpus.public ? undefined : 'This feature is only available in public projects.'"
                :class="!corpus.public ? 'is-disabled' : ''"
                target="_blank"
              >
                <i class="icon-eye"></i>
                View in UV
              </a>
              <a class="dropdown-item" :href="manifestUri(elementId)" target="_blank">
                <i class="icon-code"></i>
                IIIF Manifest
              </a>
            </template>
          </div>
        </div>
      </div>
    </template>

    <!-- Process actions -->
    <div class="dropdown is-right is-hoverable">
      <div class="dropdown-trigger">
        <a class="navbar-link">
          Processes
        </a>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <!-- Training -->
          <span class="dropdown-item menu-category">Training</span>
          <!-- Add random elements to the sets of a dataset, with write rights -->
          <a
            class="dropdown-item"
            v-on:click="populateModal = hasContribPrivilege"
            :class="!hasContribPrivilege ? 'is-disabled' : ''"
            :title="hasContribPrivilege ? 'Populate a dataset from random elements.' : createDisabledTitle"
          >
            <i class="icon-flow-tree"></i>
            Populate a dataset
          </a>
          <!-- Dataset processes, only on projects with admin access -->
          <a
            class="dropdown-item"
            :class="!hasAdminPrivilege ? 'is-disabled' : ''"
            v-if="!elementId"
            v-on:click.prevent="createDatasetProcess"
            :title="hasAdminPrivilege ? 'Build a new training process on this project\'s datasets.' : executeDisabledTitle"
          >
            <i class="icon-cog-alt"></i>
            Create training process
          </a>
          <!-- Inference -->
          <span class="dropdown-item menu-category">Inference</span>
          <!-- ML processes for both projects and elements, with admin access -->
          <a
            class="dropdown-item"
            :class="!hasAdminPrivilege ? 'is-disabled' : ''"
            v-on:click.prevent="createProcess"
            :title="hasAdminPrivilege ? 'Build a new ML process from those elements.' : executeDisabledTitle"
          >
            <i class="icon-play"></i>
            Create inference process
          </a>
          <router-link
            class="dropdown-item"
            :to="{ name: 'corpus-entities', params: { corpusId }, query: elementId ? { parent: elementId } : {} }"
          >
            <i class="icon-list"></i>
            View entities
          </router-link>
          <!-- Delete button for Worker Results produced by a specific WorkerVersion on a project or an element -->
          <a
            :class="!hasAdminPrivilege ? 'is-disabled' : ''"
            class="dropdown-item has-text-danger"
            v-on:click="deleteResultsModal = hasAdminPrivilege"
            :title="hasAdminPrivilege ? 'Delete results produced by a worker version.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete worker results
          </a>
        </div>
      </div>
    </div>

    <div class="dropdown is-right is-hoverable">
      <div class="dropdown-trigger">
        <a class="navbar-link">
          Display
        </a>
      </div>
      <div class="dropdown-menu" :class="{ 'focused': updatingPageSize }" role="menu">
        <div
          class="dropdown-content has-text-right"
          v-on:focus.stop
        >
          <div class="dropdown-item" v-if="element">
            <input
              id="switchDetails"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayDetails"
              v-on:change="toggleElementDetails"
            />
            <label v-on:focus.prevent="" for="switchDetails">Details</label>
          </div>
          <div class="dropdown-item" v-if="elementType?.folder">
            <input
              id="switchTree"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayFolderTree"
              v-on:change="toggleFolderTree"
            />
            <label for="switchTree">Children tree</label>
          </div>
          <div class="dropdown-item" v-else-if="elementType && !elementType.folder">
            <input
              id="switchTree"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayAnnotationsTree"
              v-on:change="toggleAnnotationsTree"
            />
            <label for="switchTree">Annotations tree</label>
          </div>
          <div class="dropdown-item" v-if="element">
            <input
              id="switchEntityTypes"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayEntityTypes"
              v-on:change="toggleEntityTypes"
            />
            <label for="switchEntityTypes">Entity types</label>
          </div>
          <template v-if="elementId === null || elementType?.folder">
            <div class="dropdown-item">
              <input
                id="switchLayout"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="elementsTableLayout"
                v-on:change="toggleLayout"
              />
              <label for="switchLayout">List view</label>
            </div>
            <div class="dropdown-item">
              <input
                id="switchCompactDisplay"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="compactDisplay"
                v-on:change="toggleCompactDisplay"
                :class="elementsTableLayout ? 'is-disabled' : ''"
              />
              <label for="switchCompactDisplay">Compact display</label>
            </div>
            <div class="dropdown-item">
              <input
                id="switchClasses"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="displayElementClasses"
                v-on:change="toggleElementClasses"
              />
              <label for="switchClasses">Classes</label>
            </div>
            <div class="dropdown-item">
              <label class="is-size-6 mr-2">Pagination size</label>
              <div class="select is-small page-select">
                <select
                  :value="navigationPageSize"
                  v-on:focus="updatingPageSize = true"
                  v-on:blur="updatingPageSize = false"
                  v-on:change="selectPageSize"
                >
                  <option
                    v-for="size in NAVIGATION_PAGE_SIZES"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <AddFolderModal
      v-model="addFolderModal"
      :corpus-id="corpusId"
      :parent-id="elementId"
    />

    <ExportsModal v-model="exportsModal" :corpus-id="corpusId" />

    <Modal v-if="element" v-model="moveModal" title="Move this element">
      <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="canContain ? [elementId] : null" />
      <div class="content message is-warning mb-3" v-if="elementDirectParents && elementDirectParents.length > 1">
        <div class="message-body">
          This element has {{ elementDirectParents.length }} parents:
          <ul class="mt-1 mb-1">
            <li v-for="parent in elementDirectParents" :key="parent.id">
              <strong>{{ typeName(parent.type) }} {{ parent.name }}</strong>
            </li>
          </ul>
          Note that all the links towards previous parents will be removed.
        </div>
      </div>
      <span>
        Moving element <strong>{{ typeName(element.type) }} {{ element.name }}</strong> will also move all its children elements and all attached transcriptions, entities, and classifications.
        <br />
        All current paths will be replaced by the newly created path.
      </span>
      <br />
      <span>This action is irreversible.</span>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': moveLoading }"
          :disabled="!pickedFolder || null"
          v-on:click="performMove"
        >
          Move {{ truncateShort(element.name) }}
        </button>
      </template>
    </Modal>

    <Modal v-model="createParentModal" title="Link this element to another parent">
      <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="canContain ? [elementId] : null" />
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': createParentLoading }"
          :disabled="!pickedFolder || null"
          v-on:click="performCreateParent"
        >
          Link element
        </button>
      </template>
    </Modal>

    <Modal v-model="deleteFilteredModal" title="Delete elements">
      <p>
        Are you sure you want to delete all the listed elements in this {{ elementId ? 'folder' : 'project' }}?<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': deleteFilteredLoading }"
          v-on:click="performDeleteFiltered"
        >
          Delete
        </button>
      </template>
    </Modal>
  </div>

  <DeleteResultsModal
    v-model="deleteResultsModal"
    :corpus-id="corpusId"
    :element-id="elementId"
  />
  <DatasetPopulateModal
    v-if="hasContribPrivilege"
    v-model="populateModal"
    :corpus-id="corpusId"
    :parent-id="elementId"
  />
</template>

<script>
import { isEmpty } from 'lodash'
import { mapStores, mapState, mapActions } from 'pinia'
import {
  mapState as mapVuexState,
  mapGetters as mapVuexGetters
} from 'vuex'

import { EXPORT_FORMATS, NAVIGATION_PAGE_SIZES } from '@/config'
import { manifestUri, uvUri, miradorUri, createProcessRedirect } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import {
  useDisplayStore,
  useNotificationStore,
  useCorporaStore,
  useElementStore
} from '@/stores'

import Modal from '@/components/Modal'
import AddFolderModal from '@/components/Navigation/AddFolderModal'
import FolderPicker from '@/components/Navigation/FolderPicker'
import ExportsModal from '@/components/Corpus/Exports/ExportsModal.vue'
import ExportProcessModal from './ExportProcessModal.vue'
import DeleteResultsModal from '@/components/Process/Workers/DeleteResultsModal'
import DatasetPopulateModal from '@/components/Corpus/Datasets/Populate.vue'
import DeleteModal from '@/components/SingleDeletionModal.vue'

export default {
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  components: {
    Modal,
    AddFolderModal,
    FolderPicker,
    ExportsModal,
    ExportProcessModal,
    DeleteResultsModal,
    DatasetPopulateModal,
    DeleteModal
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    NAVIGATION_PAGE_SIZES,
    EXPORT_FORMATS,
    deleteLoading: false,
    deleteResultsModal: false,
    deleteFilteredModal: false,
    deleteFilteredLoading: false,
    addFolderModal: false,
    exportsModal: false,
    exportProcessModal: false,
    updatingPageSize: false,
    moveModal: false,
    createParentModal: false,
    moveLoading: false,
    createParentLoading: false,
    populateModal: false,
    /**
     * pickedFolder is shared between the two Folder Picker components (the one from moving
     * the element and the one from linking it to a new parent element); this only affects
     * the selected folder display in the Folder Pickers (if you select a folder in one of
     * them, it will appear as selected when you open the other), causing no other issue as
     * the two Folder Pickers cannot be open at the same time.
     */
    pickedFolder: null
  }),
  computed: {
    ...mapVuexGetters('auth', [
      'isAdmin',
      'isVerified',
      'hasFeature'
    ]),
    ...mapState(useElementStore, {
      firstParentId: 'firstParentId',
      // canWrite is already defined in corporaMixin
      canWriteElement: 'canWrite'
    }),
    ...mapVuexGetters('navigation', { canDeleteFiltered: 'canDelete' }),
    ...mapVuexState('navigation', { filteredElements: 'elements' }),
    ...mapState(useElementStore, [
      'elements',
      'childrenPagination',
      'neighbors'
    ]),
    ...mapState(useDisplayStore, [
      'displayDetails',
      'displayFolderTree',
      'displayAnnotationsTree',
      'displayEntityTypes',
      'displayElementClasses',
      'navigationPageSize',
      'elementsTableLayout',
      'compactDisplay'
    ]),
    ...mapState(useCorporaStore, { deleteCorpus: 'delete' }),
    ...mapVuexState('selection', ['selection']),
    ...mapVuexState('auth', ['user']),
    ...mapStores(useElementStore),
    element () {
      if (!this.elementId) return
      return this.elements[this.elementId] || null
    },
    elementType () {
      if (!this.elementId || !this.element) return
      return this.getType(this.element.type)
    },
    totalChildrenCount () {
      return this.elementId && this.childrenPagination[this.elementId]?.count
    },
    elementDirectParents () {
      if (!this.elementId || !Array.isArray(this.neighbors[this.elementId])) return null
      // Fetch all direct parents of this element.
      const firstParents = this.neighbors[this.elementId]
        .filter(({ path }) => path?.length > 0)
        .map(({ path }) => path[path.length - 1])
      // Prevent returning duplicated parents by filtering out identical objects.
      return firstParents.filter((parent, index, parents) => parents.findIndex(p => (p.id === parent.id)) === index)
    },
    hasContribPrivilege () {
      /*
       * Either these actions are shown for an element and the user has write rights on the element,
       * or the user has write rights on the corpus.
       */
      return this.isVerified && (
        (this.elementId && this.canWriteElement(this.elementId)) ||
        (this.corpus && this.canWrite(this.corpus))
      )
    },
    hasAdminPrivilege () {
      /*
       * The user had admin rights on the corpus.
       */
      return this.isVerified && (
        (this.corpus && this.canAdmin(this.corpus))
      )
    },
    canContain () {
      // Determine if the current view show a corpus or a folder
      return !this.elementId || (this.elementType && this.elementType.folder)
    },
    hasFolderTypes () {
      return this.corpus && this.folderTypes(this.corpus.types).length > 0
    },
    createDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.hasAdminPrivilege) prefix = 'You must have a contributor access to this corpus in order'
      else if (!this.hasFolderTypes) prefix = 'At least one folder type should be declared on the corpus'
      else return null
      return `${prefix} to perform this action.`
    },
    executeDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.hasAdminPrivilege) prefix = 'You must have an admin access to this corpus in order'
      else return null
      return `${prefix} to perform this action.`
    },
    deleteFilteredTitle () {
      if (this.canDeleteFiltered) {
        return 'Delete all ' +
          (this.elementId ? 'child elements of this folder' : 'elements in this project') +
          ', applying the currently selected filters.'
      }
      return this.executeDisabledTitle ?? 'The currently selected navigation filters cannot be applied to a filtered deletion.'
    },
    S3ImportTitle () {
      if (this.canImportFromS3) return 'Import files from a bucket into a new folder.'
      else if (this.hasContribPrivilege && this.hasFolderTypes && !(this.user.can_ingest || this.user.is_admin)) {
        return 'You do not have the required permission to perform this action'
      }
      return this.createDisabledTitle
    },
    selected () {
      return this.selection && Array.isArray(this.selection[this.corpusId]) && this.selection[this.corpusId].includes(this.elementId)
    },
    canSelectAll () {
      return this.isVerified && this.hasFeature('selection') && this.canContain && this.filteredElements?.results?.length
    },
    canImportFromS3 () {
      return this.hasContribPrivilege && this.hasFolderTypes && (this.user.can_ingest || this.user.is_admin)
    }
  },
  methods: {
    ...mapActions(useDisplayStore, [
      'toggleElementDetails',
      'toggleFolderTree',
      'toggleAnnotationsTree',
      'toggleEntityTypes',
      'toggleElementClasses',
      'toggleElementsLayout',
      'toggleCompactDisplay',
      'setPageSize'
    ]),
    ...mapActions(useNotificationStore, ['notify']),
    isEmpty,
    // Update page size and remove focus in the dropdown
    selectPageSize (event) {
      const value = Number.parseInt(event.target.value, 10)
      if (value && NAVIGATION_PAGE_SIZES.includes(value)) this.setPageSize(value)
      document.documentElement.focus()
      this.updatingPageSize = false
    },
    // Cannot call toggleElementsLayout directly from v-on:click because it tries to parse the event as a string
    toggleLayout () {
      this.toggleElementsLayout()
    },
    // Retrieve navigation filters when creating a process
    getProcessFilters () {
      const filters = {}
      if (!this.$route.query) return filters

      // Only name, type, recursive and class_id parameters are handled yet
      const { name, type, top_level: topLevel, recursive, class_id: classId } = this.$route.query

      if (name) filters.element_name_contains = name
      if (type) filters.element_type = type
      if (classId) filters.ml_class_id = classId
      /*
       * Use the backend logic for boolean parameters:
       * recursive (only on ListElementChildren) should cause load_children to be set only when it is not empty,
       * false or zero.
       * top_level (only on ListElements) should have the opposite behavior, since it means "not recursive".
       */
      if (recursive) filters.load_children = !['false', '0'].includes(recursive.toString().toLowerCase())
      else if (topLevel) filters.load_children = ['false', '0'].includes(topLevel.toString().toLowerCase())
      return filters
    },
    createProcess () {
      if (!this.hasAdminPrivilege) return
      const payload = { corpus: this.corpusId, ...this.getProcessFilters() }
      if (this.elementId) payload.element = this.elementId
      createProcessRedirect(this.$store, this.$router, payload)
    },
    createDatasetProcess () {
      if (!this.hasAdminPrivilege || this.elementId) return
      createProcessRedirect(this.$store, this.$router, { corpus: this.corpusId, mode: 'dataset' })
    },
    async performDeleteFiltered () {
      if (!this.canDeleteFiltered) return
      this.deleteFilteredLoading = true
      await this.$store.dispatch('navigation/delete')
      this.deleteFilteredModal = false
      this.deleteFilteredLoading = false
    },
    async performMove () {
      if (!this.hasContribPrivilege) return
      this.moveLoading = true
      try {
        await this.elementStore.move(this.elementId, this.pickedFolder.id)
        this.moveModal = false

        // Redirect to the first parent element when available, otherwise redirect to the element's corpus.
        if (this.firstParentId(this.elementId)) {
          this.$router.push({ name: 'element-details', params: { id: this.firstParentId(this.elementId) } })
        } else {
          this.$router.push({ name: 'navigation', params: { corpusId: this.corpusId } })
        }
      } finally {
        this.moveLoading = false
      }
    },
    async performCreateParent () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.createParentLoading = true
      try {
        await this.elementStore.createParent(this.elementId, this.pickedFolder.id)
        this.createParentModal = false
      } finally {
        this.createParentLoading = false
      }
    },
    select () {
      if (!this.isVerified || !this.hasFeature('selection') || !this.element) return
      if (this.selected) this.$store.dispatch('selection/unselect', this.element)
      else this.$store.dispatch('selection/select', { elements: [this.element] })
    },
    selectAll () {
      if (!this.canSelectAll) return
      this.$store.dispatch('selection/select', { elements: this.filteredElements.results })
    },
    uvUri,
    miradorUri,
    manifestUri
  }
}
</script>

<style scoped>
.dropdown-content > .dropdown-item {
  display: block;
  white-space: nowrap;
}
.dropdown-content > .menu-category {
  text-transform: uppercase;
  text-align: center;
  background-color: hsl(0deg, 0%, 96%);
}
.page-select {
  height: 1.75rem;
}
.dropdown-menu.focused {
  display: block;
}
/* The default height set by bulma-switch is excessive within a dropdown */
.dropdown-menu .switch[type="checkbox"] + label {
  height: unset;
}
</style>
