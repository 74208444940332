
import { PropType, defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, hasWorkerRun, orientationStyle } from '@/helpers'
import { useNotificationStore, useTranscriptionStore } from '@/stores'
import { Element, ElementBase, TextOrientation } from '@/types'
import { Transcription } from '@/types/transcription'
import { isAxiosError } from 'axios'

/**
 * A transcription edition form.
 * Emits a `close` event to notify the parent component when a transcription was edited successfully
 * or when the user clicked the Cancel button.
 */
export default defineComponent({
  emits: ['close'],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
      // Cannot edit a transcription with a worker run
      validator:
        (transcription: Transcription): boolean => {
          return transcription?.text.length > 0 && transcription?.orientation && !hasWorkerRun(transcription)
        }

    }
  },
  data: () => ({
    newText: '',
    newOrientation: '' as TextOrientation,
    loading: false,
    fieldErrors: {},
    TEXT_ORIENTATIONS
  }),
  mounted () {
    this.newText = this.transcription.text
    this.newOrientation = this.transcription.orientation;
    (this.$refs.textInput as HTMLInputElement | null)?.focus()
  },
  computed: {
    /**
     * How many rows to allow in the textarea.
     */
    rows (): number {
      return Math.max(1, this.newText.split(/\r\n|\r|\n/).length)
    },
    canUpdate (): boolean {
      return this.newText.trim() !== '' && this.newOrientation !== null && this.newOrientation in TEXT_ORIENTATIONS
    },
    updateDisabledTitle (): string {
      if (!this.newOrientation || !(this.newOrientation in TEXT_ORIENTATIONS)) return 'A valid text orientation value is required'
      else if (!this.newText.trim()) return 'A transcription text is required'
      return ''
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useTranscriptionStore, ['update']),
    orientationStyle,
    setErrors (error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!Object.keys(error).includes('response')) this.fieldErrors = { error: errorParser(error) }
      if (isAxiosError(error)) {
        if (typeof error.response?.data !== 'object') this.fieldErrors = { error: errorParser(error) }
        else this.fieldErrors = error.response.data
      }
    },
    async updateTranscription () {
      if (!this.canUpdate) return
      this.loading = true
      try {
        await this.update(this.element.id, this.transcription.id, { text: this.newText, orientation: this.newOrientation })
        // Notify the parent component that the edition is complete
        this.$emit('close')
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred when updating the transcription: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  }
})
