
import { PropType, defineComponent } from 'vue'
import { METADATA_TYPES } from '@/config'
import { truncateMixin } from '@/mixins'
import { MetaDataType } from '@/types/metadata'
import { SearchResult } from '@/types/search'
import ConfidenceTag from '@/components/ConfidenceTag.vue'

export default defineComponent({
  components: {
    ConfidenceTag
  },
  mixins: [
    truncateMixin
  ],
  props: {
    document: {
      type: Object as PropType<SearchResult>,
      required: true
    }
  },
  data: () => ({
    METADATA_TYPES,
    imgFailed: false
  }),
  methods: {
    iconClass (type: MetaDataType) {
      return METADATA_TYPES[type].icon || 'icon-feather'
    },
    truncated (text: string) {
      if (text.length < 100) return text
      return text.substring(0, 100) + '[...]'
    }
  }
})
