import axios from 'axios'
import { PageNumberPaginationParameters, unique } from '.'
import { PageNumberPagination, UUID } from '@/types'
import { CreatedDataFile, DataFile } from '@/types/files'
import { Process } from '@/types/process'

// List files on a corpus.
export const listDataFiles = unique(async (id: UUID, params: PageNumberPaginationParameters): Promise<PageNumberPagination<DataFile>> =>
  (await axios.get(`/process/files/${id}/`, { params })).data)

export type DataFileCreatePayload = Pick<CreatedDataFile, 'name' | 'content_type' | 'corpus' | 'size'>

/*
 * Create a new DataFile.
 * Will return an S3 URL to upload the file.
 */
export const createDataFile = async (payload: DataFileCreatePayload): Promise<CreatedDataFile> =>
  (await axios.post('/process/files/create/', payload)).data

export type DataFileUpdatePayload = Partial<Pick<DataFile, 'name' | 'status'>>

// Update an existing DataFile.
export const updateDataFile = async (id: UUID, payload: DataFileUpdatePayload): Promise<DataFile> =>
  (await axios.patch(`/process/file/${id}/`, payload)).data

// Remove a DataFile by ID.
export const destroyDataFile = unique(async (id: UUID) => axios.delete(`/process/file/${id}/`))

export interface FilesProcessPayload {
  files: UUID[]
  folder_type: string
  element_type: string
  folder_id?: string | null
  farm_id?: string | null
}

// Run a file import process from files
export const importFromFiles = async (payload: FilesProcessPayload): Promise<Process> =>
  (await axios.post('/process/fromfiles/', payload)).data
