import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fbfcd16"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditModal = _resolveComponent("EditModal")!
  const _component_DeleteModal = _resolveComponent("DeleteModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _mergeProps({ class: "is-pulled-right" }, _ctx.$attrs), [
      (!_ctx.metadata.entity)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass(["icon icon-edit has-text-info", { 'disabled': !_ctx.editable }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editModal = _ctx.editable)),
            title: _ctx.editable ? 'Edit metadata' : 'Only an administrator can edit this protected metadata'
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: _normalizeClass(["icon icon-trash has-text-danger", { 'disabled': !_ctx.editable }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteModal = _ctx.editable)),
        title: _ctx.editable ? 'Delete metadata' : 'Only an administrator can delete this protected metadata'
      }, null, 10, _hoisted_2)
    ], 16),
    (_ctx.editModal)
      ? (_openBlock(), _createBlock(_component_EditModal, {
          key: 0,
          modelValue: _ctx.editModal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editModal) = $event)),
          "corpus-id": _ctx.corpusId,
          "element-id": _ctx.elementId,
          metadata: _ctx.metadata
        }, null, 8, ["modelValue", "corpus-id", "element-id", "metadata"]))
      : _createCommentVNode("", true),
    (_ctx.deleteModal)
      ? (_openBlock(), _createBlock(_component_DeleteModal, {
          key: 1,
          modelValue: _ctx.deleteModal,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deleteModal) = $event)),
          "element-id": _ctx.elementId,
          metadata: _ctx.metadata
        }, null, 8, ["modelValue", "element-id", "metadata"]))
      : _createCommentVNode("", true)
  ], 64))
}