
import { isEmpty } from 'lodash'
import { defineComponent, PropType } from 'vue'

import { METADATA_TYPES } from '@/config'
import { UUID } from '@/types'
import { MetaData } from '@/types/metadata'

import InterpretedDate from '@/components/InterpretedDate.vue'
import MetadataActions from './Actions.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'

/**
 * Displays a single non-markdown metadata.
 */
export default defineComponent({
  components: {
    InterpretedDate,
    MetadataActions,
    WorkerRunSummary
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
      validator: (value: unknown): boolean => value !== null && typeof value === 'object' && 'type' in value && value.type !== 'markdown'
    }
  },
  computed: {
    containsDates (): boolean {
      return !this.metadata.entity && !isEmpty(this.metadata.dates)
    },
    iconClass (): string {
      return METADATA_TYPES[this.metadata.type]?.icon ?? 'icon-feather'
    },
    iconTitle (): string {
      const typeDisplay = METADATA_TYPES[this.metadata.type]?.display ?? 'Metadata'
      if (this.metadata.entity) return `${typeDisplay} - ${this.metadata.entity.type.name}`
      return typeDisplay
    }
  }
})
