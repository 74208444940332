import { assign, groupBy } from 'lodash'
import * as api from '@/api'
import { errorParser } from '@/helpers'
import { useJobsStore, useElementStore, useNotificationStore } from '@/stores'

export const initialState = () => ({
  count: null,
  selection: {}
})

export const mutations = {
  set (state, { count, results }) {
    if (count) state.count = count
    const newSelection = { ...state.selection }
    for (const [corpusId, elements] of Object.entries(groupBy(results, element => element.corpus.id))) {
      const elementIds = elements.map(element => element.id)
      if (Array.isArray(newSelection[corpusId])) {
        newSelection[corpusId] = [
          ...new Set([...newSelection[corpusId], ...elementIds])
        ]
      } else newSelection[corpusId] = elementIds
    }
    state.selection = newSelection
  },
  remove (state, element) {
    const newSelection = { ...state.selection }
    if (newSelection[element.corpus.id].includes(element.id)) {
      newSelection[element.corpus.id] = newSelection[element.corpus.id].filter(id => id !== element.id)
      state.selection = newSelection
      state.count--
    }
  },
  removeCorpus (state, corpusId) {
    const newSelection = { ...state.selection }
    const removedCount = newSelection[corpusId].length
    delete newSelection[corpusId]
    state.selection = newSelection
    state.count -= removedCount
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async get ({ commit }, payload) {
    commit('reset')
    let page = payload.page || 1
    let data
    do {
      try {
        data = await api.listSelection({ ...payload, page })
        useElementStore().bulkSet(data.results)
        commit('set', data)
        page += 1
      } catch (err) {
        commit('reset')
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    } while (data.next)
  },

  async select ({ commit }, { elements }) {
    try {
      await api.addSelection(elements.map(e => e.id))
      /*
       * TODO: Remove after the elements refactoring is completed
       * While the selection module already fully uses elements,
       * parts of the elements sent to this action can still come from the old elements module;
       * in this case, selecting elements will work but they will not be displayed,
       * since they might not exist in elements's state.
       * We fix this by just asking elements to ensure they are in there.
       */
      useElementStore().bulkSet(elements)
      commit('set', { results: elements })
    } catch (err) {
      commit('reset')
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      throw err
    }
  },

  async unselect ({ commit }, element) {
    try {
      await api.removeSelection(element.id)
      commit('remove', element)
    } catch (err) {
      commit('reset')
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      throw err
    }
  },

  async clear ({ commit }) {
    try {
      await api.clearSelection()
    } catch (err) {
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      throw err
    } finally {
      commit('reset')
    }
  },

  async validateClassifications (store, { corpusId }) {
    try {
      await api.validateClassificationsSelection(corpusId)
    } catch (err) {
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      throw err
    }
  },

  async createClassifications (store, { corpusId, mlClassId }) {
    try {
      await api.createClassificationsSelection(corpusId, mlClassId)
    } catch (err) {
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      throw err
    }
  },

  async deleteElements ({ commit }, { corpusId }) {
    const notificationStore = useNotificationStore()
    try {
      await api.deleteElementsSelection(corpusId)
      notificationStore.notify({ type: 'success', text: 'Elements deletion has been scheduled.' })
      commit('removeCorpus', corpusId)
    } catch (err) {
      notificationStore.notify({ type: 'error', text: errorParser(err) })
    } finally {
      useJobsStore().list()
    }
  },

  async moveSelection (store, { corpusId, destination }) {
    const notificationStore = useNotificationStore()
    try {
      await api.moveSelection(corpusId, destination)
      notificationStore.notify({ type: 'success', text: 'Elements moving has been scheduled.' })
    } catch (err) {
      const message = err.response?.data?.destination ?? err.response?.data?.corpus_id ?? err
      notificationStore.notify({ type: 'error', text: errorParser(message) })
    } finally {
      useJobsStore().list()
    }
  },

  async createParent (store, { corpusId, parentId }) {
    const notificationStore = useNotificationStore()
    try {
      await api.createParentSelection(corpusId, parentId)
      notificationStore.notify({ type: 'success', text: 'Element linking has been scheduled.' })
    } catch (err) {
      const message = err.response?.data?.parent_id ?? err.response?.data?.corpus_id ?? err
      notificationStore.notify({ type: 'error', text: errorParser(message) })
    } finally {
      useJobsStore().list()
    }
  }
}

export const getters = {
  loaded: state => Object.values(state.selection).reduce((total, array) => { total += array.length; return total }, 0)
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
