
import { orderBy } from 'lodash'
import { mapActions, mapStores } from 'pinia'

import { ELEMENT_NAME_MAX_LENGTH } from '@/config'
import { errorParser } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import { useAnnotationStore, useNotificationStore } from '@/stores'

import ElementImage from '@/components/Image/ElementImage.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'
import { defineComponent } from 'vue'
import { isAxiosError } from 'axios'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    MLClassSelect,
    Modal
  },
  emits: ['update:modelValue'],
  props: {
    element: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    ELEMENT_NAME_MAX_LENGTH,
    loading: false,
    type: '',
    classId: '',
    // Do not allow form validation on invalid class input
    validClassification: true,
    name: '',
    // API fields validation errors
    fieldErrors: {} as { name?: string[], type?: string[] }
  }),
  mounted () {
    (this.$refs.typeSelect as HTMLSelectElement).focus()
  },
  computed: {
    ...mapStores(useAnnotationStore),
    corpusId () {
      // Corpus ID for corporaMixin
      return this.element.corpus.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    isValid () {
      return this.type && this.validClassification
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async createElement () {
      if (this.loading || !this.isValid || !this.annotationStore.selectedElement?.zone?.polygon) return
      if (!this.type) {
        this.fieldErrors = { type: ['A type is required'] }
        return
      }
      this.loading = true
      this.fieldErrors = {}
      try {
        await this.annotationStore.create({
          corpus: this.corpusId,
          name: this.name || this.annotationStore.defaultName(this.element.id, this.type),
          type: this.type,
          parent: this.element.id,
          image: this.element.zone.image.id,
          polygon: this.annotationStore.selectedElement.zone.polygon,
          classId: this.classId
        })
        this.notify({ type: 'success', text: 'Element created.' })
        // Close modal in case of success
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && (err.response?.data?.name || err?.response?.data?.type)) {
          this.fieldErrors = {
            name: err.response.data.name,
            type: err.response.data.type
          }
        }
        this.notify({ type: 'error', text: `An error occurred during element creation: ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    defaultType: {
      immediate: true,
      handler () {
        this.type = this.annotationStore.defaultType[this.corpusId] || ''
      }
    },
    defaultClass: {
      immediate: true,
      handler () {
        this.classId = this.annotationStore.defaultClass[this.corpusId] || ''
      }
    },
    type () {
      delete this.fieldErrors.type
    }
  }
})
