import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    maxlength: "10",
    placeholder: "YYYY[-MM[-DD]]",
    title: "The date must contain at least a full year, optionally a month and a day",
    class: _normalizeClass(["input", { 'is-danger': !_ctx.dateValidated, 'is-success': _ctx.currentDate.length && _ctx.dateValidated }]),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentDate) = $event))
  }, null, 2)), [
    [_vModelText, _ctx.currentDate]
  ])
}