
import { orderBy } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { truncateMixin, corporaMixin } from '@/mixins'

import DropdownContent from '@/components/DropdownContent.vue'
import Tools from '@/components/Image/Tools.vue'
import { useAnnotationStore, useElementStore } from '@/stores'
import { UUID } from '@/types'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DropdownContent,
    Tools
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useAnnotationStore, [
      'batchCreation',
      'batchDeletion',
      'batchTypeEdition',
      'tool'
    ]),
    ...mapStores(useAnnotationStore),
    element () {
      return this.elements[this.elementId]
    },
    corpusId () {
      return this.element?.corpus?.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    /**
     * Disable the batch annotation form if the selected tool does not use it.
     * Only the element creation tools (rectangle and polygon) make use of the batch annotation settings.
     */
    batchFormDisabled (): true | undefined {
      return !['rectangle', 'polygon', 'deletion', 'type-edit'].includes(this.tool) || undefined
    },
    /**
     * Disable the element type selection dropdown if not using an element creation tool.
     */
    elementTypeSelectDisabled (): true | undefined {
      return !['rectangle', 'polygon', 'type-edit'].includes(this.tool) || undefined
    },
    batchCreation: {
      get () {
        return this.annotationStore.batchCreation
      },
      set (newValue: boolean) {
        this.annotationStore.setBatchCreation(newValue)
        /*
         * Everything, except this panel, is ready to support the default classification setting.
         * The annotation module is going to expect a default class ID to be defined, so for now,
         * we just set it to never create a classification.
         * TODO: Fix the MLClassSelect to allow setting default classifications in this panel.
         */
        if (this.annotationStore.batchCreation && this.corpusId) this.annotationStore.defaultClass[this.corpusId] = null
      }
    },
    batchDeletion: {
      get () {
        return this.annotationStore.batchDeletion
      },
      set (newValue: boolean) {
        this.annotationStore.setBatchDeletion(newValue)
      }
    },
    batchTypeEdition: {
      get () {
        return this.annotationStore.batchTypeEdition
      },
      set (newValue: boolean) {
        this.annotationStore.setBatchTypeEdition(newValue)
      }
    },
    defaultType: {
      get () {
        // Default to the empty string to make the disabled placeholder option selected
        return (this.corpusId && this.annotationStore.defaultType[this.corpusId]) ?? ''
      },
      set (type: string) {
        if (this.corpusId) this.annotationStore.defaultType[this.corpusId] = type
      }
    },
    showDefaultTypeError () {
      return (this.batchCreation || this.batchTypeEdition) && !this.defaultType
    }
  },
  watch: {
    // If we know the user's rights on the corpus, and we find that the user is not a contributor, turn annotation off
    corpus: {
      immediate: true,
      handler (newCorpus) {
        if (Array.isArray(newCorpus?.rights) && !this.canWrite(newCorpus)) this.annotationStore.toggle(false)
      }
    }
  }
})
