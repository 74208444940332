import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, mergeProps as _mergeProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aaf95fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "value" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "is-narrow pr-0" }
const _hoisted_5 = { class: "is-narrow pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InterpretedDate = _resolveComponent("InterpretedDate")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MetadataActions = _resolveComponent("MetadataActions")!
  const _component_WorkerRunSummary = _resolveComponent("WorkerRunSummary")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", {
      title: _ctx.iconTitle,
      class: "key"
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.iconClass)
      }, null, 2),
      _createTextVNode(" " + _toDisplayString(_ctx.metadata.name), 1)
    ], 8, _hoisted_1),
    _createElementVNode("td", _hoisted_2, [
      (_ctx.containsDates)
        ? (_openBlock(), _createBlock(_component_InterpretedDate, {
            key: 0,
            dates: _ctx.metadata.dates,
            "raw-date": _ctx.metadata.value.toString()
          }, null, 8, ["dates", "raw-date"]))
        : (_ctx.metadata.entity)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: { name: 'entity-details', params: { id: _ctx.metadata.entity.id } }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.metadata.entity.name), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : (_ctx.metadata.type === 'url')
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: _ctx.metadata.value.toString(),
                target: "_blank"
              }, _toDisplayString(_ctx.metadata.value), 9, _hoisted_3))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.metadata.value), 1)
              ], 64))
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createVNode(_component_MetadataActions, _mergeProps({
        "corpus-id": _ctx.corpusId,
        "element-id": _ctx.elementId,
        metadata: _ctx.metadata
      }, _ctx.$attrs), null, 16, ["corpus-id", "element-id", "metadata"])
    ]),
    _createElementVNode("td", _hoisted_5, [
      (_ctx.metadata.worker_run)
        ? (_openBlock(), _createBlock(_component_WorkerRunSummary, {
            key: 0,
            "worker-run-details": _ctx.metadata.worker_run,
            "icon-only": ""
          }, null, 8, ["worker-run-details"]))
        : _createCommentVNode("", true)
    ])
  ]))
}