
import { defineComponent, PropType } from 'vue'
import { mapState, mapStores } from 'pinia'

import { corporaMixin } from '@/mixins'
import {
  useAnnotationStore,
  useClassificationStore,
  useElementStore
} from '@/stores'
import { UUID } from '@/types'

import ConfidenceTag from '@/components/ConfidenceTag.vue'
import DeleteModal from '@/components/SingleDeletionModal.vue'
import EditionForm from '@/components/Element/EditionForm.vue'
import ItemId from '@/components/ItemId.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    ConfidenceTag,
    DeleteModal,
    EditionForm,
    ItemId,
    WorkerRunSummary
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true
    }
  },
  data: () => ({
    deleteLoading: false,
    editionModal: false
  }),
  computed: {
    ...mapState(useClassificationStore, ['classifications']),
    ...mapState(useElementStore, ['elements']),
    ...mapStores(
      useAnnotationStore,
      useElementStore
    ),
    element () {
      return this.elements[this.elementId]
    },
    isFolder (): boolean {
      return this.getType(this.element.type)?.folder ?? false
    },
    isAnnotable (): boolean {
      if (!this.mainElementId) return false
      const mainElement = this.elements[this.mainElementId]
      if (!mainElement) return false
      const mainFolder = this.getType(mainElement.type)?.folder ?? false
      return (
        mainElement.zone !== null &&
        !mainFolder &&
        this.corpus !== null &&
        this.canWrite(this.corpus)
      )
    },
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    mainElementId (): UUID | null {
      // Route parameters might be arrays of strings, but we never have this in our case
      return (this.$route?.params?.id as string | undefined) ?? null
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler (id: UUID) {
        if (!id) return
        /*
         * Do not retrieve the element again if it already exists in the store,
         * unless it lacks some of the attributes only available from RetrieveElement.
         * Some elements in the store can come from list endpoints such as those of the children tree.
         * This ensures there are no strange behaviors where some actions are only sometimes disabled when they shouldn't,
         * or some element attributes are not displayed at all.
         */
        if (!this.element || this.element.id !== id || !('rights' in this.element) || !this.classifications[this.element.id]) this.elementStore.get(id)
      }
    }
  }
})
