import { assign } from 'lodash'
import * as api from '@/api'
import { DEFAULT_PAGE_SIZE } from '@/config'
import { errorParser } from '@/helpers'
import {
  useClassificationStore,
  useCorporaStore,
  useDisplayStore,
  useJobsStore,
  useNotificationStore
} from '@/stores'

export const initialState = () => ({
  elements: null,
  scheduledDeletion: [],

  corpusId: null,
  elementId: null,
  appliedFilters: {}
})

export const mutations = {
  setElements (state, { elements, corpus = null }) {
    if (!elements) {
      state.elements = null
      return
    }
    state.elements = {
      ...elements,
      results: elements.results.map(element => {
        // We don't return corpus attribute in ListElementChildren as we already know that they're in the same corpus as their parent
        if (typeof element.corpus !== 'object') {
          if (!corpus) throw new Error('Cannot set corpus attribute on element')
          // This clone is only necessary because of conflicts between Pinia and Vuex
          return { ...element, corpus: { ...corpus } }
        } else return element
      })
    }
  },
  addToScheduledDeletion (state, id) {
    if (state.scheduledDeletion.findIndex(e => e.id === id) > 0) return
    state.scheduledDeletion.push(id)
  },
  setIds (state, { corpusId, elementId = null }) {
    state.corpusId = corpusId
    state.elementId = elementId
  },
  setFilters (state, filters) {
    state.appliedFilters = filters
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  /**
   * New unified list method, which uses the state's corpusId, elementId and appliedFilters to call
   * ListElements or ListElementChildren. Will cause a call to ListCorpus if the corpus ID does not exist yet,
   * by calling useCorporaStore().get().
   * @param {object} filters Optional filters to apply; overrides appliedFilters.
   */
  async list ({ state, commit, getters }, filters = null) {
    if (!state.corpusId) throw new Error('Missing corpus ID')
    if (filters !== null) commit('setFilters', filters)
    commit('setElements', { elements: null })
    const corpus = await useCorporaStore().get(state.corpusId)
    const endpoint = state.elementId ? api.listElementChildren : api.listElements
    const elements = await endpoint(getters.listParams)
    elements.results = useClassificationStore().cleanElementList(elements.results)
    commit('setElements', { elements, corpus })
  },

  async delete ({ state, commit, getters }, filters = null) {
    if (!state.corpusId) throw new Error('Missing corpus ID')
    if (filters !== null) commit('setFilters', filters)
    const endpoint = state.elementId ? api.deleteElementChildren : api.deleteElements
    const notificationStore = useNotificationStore()
    try {
      await endpoint(getters.baseParams)
      notificationStore.notify({ type: 'success', text: 'Element deletion has been scheduled.' })
    } catch (err) {
      notificationStore.notify({ type: 'error', text: errorParser(err) })
    } finally {
      useJobsStore().list()
    }
  }
}

export const getters = {
  baseParams: state => {
    const params = { ...state.appliedFilters }
    if (state.elementId) params.id = state.elementId
    else params.corpus = state.corpusId
    return params
  },

  listParams: (state, getters) => {
    const displayStore = useDisplayStore()
    return {
      ...getters.baseParams,
      with_zone: false,
      with_corpus: false,
      with_classes: displayStore.displayElementClasses,
      page_size: displayStore.navigationPageSize || DEFAULT_PAGE_SIZE
    }
  },

  /**
   * Check whether or not the user is allowed to delete elements on the currently browsed project
   * and with the selected navigation filters.
   */
  canDelete: (state, getters, rootState, rootGetters) => state.corpusId && rootGetters['auth/isVerified'] && (
    (useCorporaStore().corpora[state.corpusId]?.rights ?? []).includes('admin') ||
    rootGetters['auth/isAdmin']
  ) && Object.keys(state.appliedFilters).every(key => [
    'name',
    'type',
    'folder',
    'worker_version',
    'worker_run',
    'page',
    /*
     * Recursive and top_level are not supported by deletion APIs, but all deletions are always recursive
     * (we never allow setting delete_children to false in the frontend), so deleting with recursive=true
     * in a folder is equivalent to recursive=false, and top_level=true becomes equivalent to no filter at all.
     */
    'recursive',
    'top_level',
    // Those filters are just ignored, deletions are not ordered
    'order',
    'order_direction'
  ].includes(key))
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
