import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["field"]
const _hoisted_2 = { class: "field-label is-normal" }
const _hoisted_3 = { class: "field-body" }
const _hoisted_4 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_5 = ["field"]
const _hoisted_6 = { class: "field-label is-normal" }
const _hoisted_7 = { class: "field-body" }
const _hoisted_8 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownContent = _resolveComponent("DropdownContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.schemaRequired)
      ? (_openBlock(), _createBlock(_component_DropdownContent, {
          key: 0,
          id: "configurationRequired",
          title: `Required fields (${Object.values(_ctx.schemaRequired).length})`
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schemaRequired, (field, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "field",
                key: key,
                field: field
              }, [
                _createElementVNode("label", _hoisted_2, _toDisplayString(field.title), 1),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.FIELDS[field.type] && _ctx.configuration[key] !== undefined)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.FIELDS[field.type].component), {
                        key: 0,
                        field: field,
                        "field-id": `config-field-id-${_ctx.uid}-${key}`,
                        modelValue: _ctx.configuration[key],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.configuration[key]) = $event),
                        class: _normalizeClass({ 'is-danger': Boolean(_ctx.configurationErrors[key]) }),
                        "worker-id": _ctx.workerId
                      }, null, 8, ["field", "field-id", "modelValue", "onUpdate:modelValue", "class", "worker-id"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.configurationErrors[key] && field.type !== 'list')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.configurationErrors[key]), 1))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_1))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.schemaOptional)
      ? (_openBlock(), _createBlock(_component_DropdownContent, {
          key: 1,
          id: "configurationOptional",
          title: `Optional fields (${Object.values(_ctx.schemaOptional).length})`,
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schemaOptional, (field, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "field",
                key: key,
                field: field
              }, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(field.title), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.FIELDS[field.type] && _ctx.configuration[key] !== undefined)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.FIELDS[field.type].component), {
                        key: 0,
                        field: field,
                        "field-id": `config-field-id-${_ctx.uid}-${key}`,
                        class: _normalizeClass({ 'is-danger': Boolean(_ctx.configurationErrors[key]) }),
                        modelValue: _ctx.configuration[key],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.configuration[key]) = $event),
                        "worker-id": _ctx.workerId
                      }, null, 8, ["field", "field-id", "class", "modelValue", "onUpdate:modelValue", "worker-id"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.configurationErrors[key] && field.type !== 'list')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.configurationErrors[key]), 1))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_5))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}