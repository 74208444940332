
import { uniqueId } from 'lodash'
import { mapState, mapActions } from 'pinia'
import { corporaMixin, truncateMixin } from '@/mixins'
import { UUID_REGEX } from '@/config'
import Modal from '@/components/Modal.vue'
import {
  useMLResultsStore,
  useWorkerStore,
  useElementStore
} from '@/stores'
import { defineComponent, PropType } from 'vue'
import { UUID, WorkerVersionCache, Element, ElementBase } from '@/types'
import { DeleteWorkerResultsParameters } from '@/api'
import { isAxiosError } from 'axios'
import WorkerVersionSummary from '@/components/Process/Workers/Versions/Summary.vue'
import ModelVersionSummary from '@/components/Model/Versions/Summary.vue'
import ConfigurationSummary from '@/components/Process/Workers/Configurations/Summary.vue'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    Modal,
    WorkerVersionSummary,
    ModelVersionSummary,
    ConfigurationSummary
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    /**
     * Id of the corpus to delete results on
     */
    corpusId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      required: true
    },
    /**
     * Limit results deletion to an element and its children
     * Cannot be set together with the selection prop
     */
    elementId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      default: null
    },
    /**
     * Limit results deletion to a selection of elements
     * Cannot be set together with the elementId prop
     */
    selection: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    advancedMode: false,
    /**
     * Optional worker version cache to delete
     */
    cacheUUIDToDelete: null as UUID | null,
    /**
     * Custom fields for manual deletion
     */
    workerRunId: '',
    workerVersionId: '',
    modelVersionId: '',
    configurationId: '',
    configurationField: null as boolean | null,
    configurationRadio: {
      Any: null,
      None: false,
      UUID: true
    },
    confirmationModal: false,
    // Mappings of [field, error_msg] for the manual deletion form
    errors: {} as { [key: string]: Array<string> }
  }),
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useWorkerStore, ['workerRuns']),
    ...mapState(useMLResultsStore, {
      workerVersionsCache (store): Record<UUID, WorkerVersionCache> {
        // @ts-expect-error `this` typed incorrectly https://pinia.vuejs.org/core-concepts/state.html#Usage-with-the-Options-API
        return store.corpusWorkerVersionsCache[this.corpusId] ?? null
      }
    }),
    originDescription (): string {
      if (this.element?.name !== undefined) {
        return ` on "${this.element.name}" and its children`
      } else if (this.selection && this.corpus?.name !== undefined) {
        return ` on selected elements from "${this.corpus.name}"`
      } else {
        return ` on project "${this.corpus?.name}"`
      }
    },
    deletesAll (): boolean {
      return (
        this.advancedMode &&
        this.workerVersionId === '' &&
        this.modelVersionId === '' &&
        this.configurationField === null
      )
    },
    element (): Element | ElementBase | null {
      if (!this.elementId) return null
      return this.elements[this.elementId] || null
    },
    versionCacheToDelete (): WorkerVersionCache | null {
      if (this.cacheUUIDToDelete === null) return null
      return this.workerVersionsCache[this.cacheUUIDToDelete]
    },
    deleteMessages (): Array<string> {
      const messages = []
      // Elements to be deleted
      messages.push(`You are about to delete results ${this.originDescription}`)
      // Worker version
      const versionName = this.truncateLong(
        this.versionCacheToDelete?.worker_version?.worker?.name ||
          this.workerVersionId ||
          'Any'
      )
      messages.push(`• Worker version: ${versionName}`)
      // Model version
      if (this.advancedMode) {
        messages.push(`• Model version: ${this.modelVersionId || 'Any'}`)
      } else if (this.versionCacheToDelete?.model_version) {
        messages.push(
          `• Model version: ${this.versionCacheToDelete.model_version.model.name}`
        )
      }
      // Worker configuration
      const confName = this.advancedMode
        ? this.configurationId ||
          (this.configurationField === null && 'Any') ||
          'None'
        : this.truncateLong(
          this.versionCacheToDelete?.worker_configuration?.name || 'None'
        )
      messages.push(`• Configuration: ${confName}`)
      return messages
    },
    payload () {
      const payload: DeleteWorkerResultsParameters = {
        use_selection: this.selection
      }
      if (this.elementId) payload.element_id = this.elementId
      if (this.advancedMode) {
        if (this.workerRunId) payload.worker_run_id = this.workerRunId
        if (this.workerVersionId) { payload.worker_version_id = this.workerVersionId }
        if (this.modelVersionId) payload.model_version_id = this.modelVersionId
        if (this.configurationField !== null) {
          payload.configuration_id = this.configurationField
            ? this.configurationId
            : false
        }
      } else if (this.versionCacheToDelete !== null) {
        payload.worker_version_id = this.versionCacheToDelete.worker_version.id
        payload.configuration_id =
          this.versionCacheToDelete?.worker_configuration?.id ?? false
        if (this.versionCacheToDelete?.model_version?.id) { payload.model_version_id = this.versionCacheToDelete.model_version.id }
      }
      return payload
    },
    inWorkerRunMode (): boolean {
      return this.workerRunId !== ''
    },
    /**
     * Value for the ID attribute of the advanced mode switch.
     * It should be unique for each switch to work correctly with multiple instances of the worker deletion modal.
     */
    advancedSwitchId () {
      return `advanced-switch-${uniqueId()}`
    },
    targetWorkerRun () {
      if (!this.workerRunId) return null
      return this.workerRuns[this.workerRunId]
    }
  },
  methods: {
    ...mapActions(useMLResultsStore, [
      'listWorkerVersionsCache',
      'deleteWorkerResults'
    ]),
    ...mapActions(useWorkerStore, ['getWorkerRun']),
    updateModelValue (value: boolean) {
      this.$emit('update:modelValue', value)
    },
    select (cacheId: UUID) {
      this.cacheUUIDToDelete = cacheId
      this.confirmationModal = true
    },
    setConfigurationField (value: boolean | null) {
      this.configurationField = value
    },
    async retrieveWorkerRun () {
      if (this.workerRuns[this.workerRunId]) return
      this.loading = true
      try {
        await this.getWorkerRun(this.workerRunId)
      } catch (err) {
        /**
         * We catch the error silently because when the confirmation modal opens,
         * we display a notification there to warn that the worker run could not be
         * retrieved. Also, displaying an error notification but still opening the
         * modal might be confusing for users.
         */
      } finally {
        this.loading = false
      }
    },
    async checkManual () {
      this.errors = {}
      if (this.workerRunId && !UUID_REGEX.test(this.workerRunId)) {
        this.errors.worker_run_id = ['This value must be a valid UUID.']
      }
      if (this.workerVersionId && !UUID_REGEX.test(this.workerVersionId)) {
        this.errors.worker_version_id = ['This value must be a valid UUID.']
      }
      if (this.modelVersionId && !UUID_REGEX.test(this.modelVersionId)) {
        this.errors.model_version_id = ['This value must be a valid UUID.']
      }
      if (this.configurationId && !UUID_REGEX.test(this.configurationId)) {
        this.errors.configuration_id = ['This value must be a valid UUID.']
      }
      if (this.configurationField === true && this.configurationId === '') {
        this.errors.configuration_id = ['This value must be set.']
      }
      if (Object.keys(this.errors).length === 0) {
        if (this.workerRunId) await this.retrieveWorkerRun()
        this.confirmationModal = true
      }
    },
    async performDelete () {
      this.errors = {}
      // No need to check for access rights, as the component can only be accessed by corpus admins
      this.loading = true

      try {
        await this.deleteWorkerResults(this.corpusId, this.payload)
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err)) this.errors = err?.response?.data || {}
      } finally {
        this.confirmationModal = false
        this.advancedMode = false
        this.loading = false
      }
    }
  },
  watch: {
    modelValue (newValue: boolean) {
      if (newValue && this.workerVersionsCache === null) {
        this.listWorkerVersionsCache(this.corpusId)
      }
    },
    confirmationModal (newValue: boolean) {
      if (newValue === false) {
        this.cacheUUIDToDelete = null
      }
    },
    configurationField (newValue: boolean | null) {
      if ([false, null].includes(newValue)) this.configurationId = ''
    }
  }
})
