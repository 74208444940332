
import { defineComponent } from 'vue'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { corporaMixin } from '@/mixins'
import { useAnnotationStore } from '@/stores'
import { ToolName } from '@/stores/annotation'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(useAnnotationStore, ['enabled', 'tool']),
    ...mapWritableState(useAnnotationStore, ['selectedElement'])
  },
  methods: {
    ...mapActions(useAnnotationStore, ['setTool', 'setBatchDeletion', 'setBatchCreation', 'setBatchTypeEdition']),
    changeTool (newTool: ToolName) {
      // Unselect element unless switching between median point and select (one less click)
      const keepSelected = ['median', 'select']
      if (!(keepSelected.includes(this.tool) && keepSelected.includes(newTool))) this.selectedElement = null
      // Deactivate batch options unless switching between the two element creation tools
      const keepBatch = ['polygon', 'rectangle']
      this.setBatchDeletion(false)
      if (!(keepBatch.includes(this.tool) && keepBatch.includes(newTool))) this.setBatchCreation(false)
      this.setBatchTypeEdition(false)
      // Update tool
      this.setTool(newTool)
    },
    edit () {
      if (!this.canWrite(this.corpus)) return
      if (this.selectedElement && this.tool !== 'select') {
        // Automatically set the edition mode if an element is selected
        this.setTool('select')
      }
      this.toggle(true)
    }
  }
})
