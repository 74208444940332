
import { mapState, mapActions } from 'pinia'
import { corporaMixin } from '@/mixins'
import { errorParser } from '@/helpers'
import { useElementStore, useEntityStore } from '@/stores'

import Paginator from '@/components/Paginator.vue'
import Row from '@/components/Entity/Row.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    Paginator,
    Row
  },
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    error: null as string | null,
    nameFilter: ''
  }),
  computed: {
    ...mapState(useEntityStore, ['entities']),
    ...mapState(useElementStore, ['elements']),
    element () {
      if (typeof this.$route.query.parent !== 'string') return null
      return this.elements[this.$route.query.parent]
    },
    parentName () {
      if (this.$route.query.parent) return this.element?.name
      else if (this.corpus) return this.corpus.name
      return null
    },
    parentRoute () {
      if (typeof this.$route.query.parent === 'string') {
        return {
          name: 'element-details',
          params: { id: this.$route.query.parent }
        }
      }
      return {
        name: 'navigation',
        params: { corpusId: this.corpusId }
      }
    }
  },
  methods: {
    ...mapActions(useEntityStore, ['list']),
    updateRoute () {
      const query = { ...this.$route.query }
      // Always reset the page number to 1
      delete query.page
      if (this.nameFilter) query.name = this.nameFilter
      else delete query.name
      this.$router.push({ ...this.$route, query })
    },
    async load (query: { parent?: string, name?: string }) {
      this.loading = true
      if (query.parent && !(query.parent in this.elements)) useElementStore().get(query.parent)
      this.nameFilter = query.name ?? ''
      try {
        await this.list(this.corpusId, query)
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter ({ query }, from, next) {
    // @ts-expect-error TS cannot detect the .load() method
    next(vm => vm.load(query))
  },
  beforeRouteUpdate ({ query }) {
    this.load(query)
  }
})
