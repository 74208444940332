
import { defineComponent, PropType } from 'vue'
import { mapStores } from 'pinia'
import { mapGetters as mapVuexGetters } from 'vuex'

import { corporaMixin } from '@/mixins'
import { useAllowedMetaDataStore, useMetaDataStore } from '@/stores'
import { UUID } from '@/types'
import { MetaData } from '@/types/metadata'

import DeleteModal from './DeleteModal.vue'
import EditModal from './EditModal.vue'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  components: {
    DeleteModal,
    EditModal
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true
    }
  },
  data: () => ({
    editModal: false,
    deleteModal: false
  }),
  computed: {
    ...mapStores(useMetaDataStore, useAllowedMetaDataStore),
    ...mapVuexGetters('auth', ['isAdmin']),
    editable (): boolean {
      // Admins can edit anything
      if (this.isAdmin) return true
      // Non-admins need write access
      if (!this.canWrite(this.corpus)) return false

      // Non-admins need for the metadata to be allowed, but we might not know if it is if that wasn't loaded yet
      return !(this.corpusId in this.allowedMetadataStore.allowedMetadata) ||
        this.allowedMetadataStore.allowedMetadata[this.corpusId].some(({ name, type }) => name === this.metadata.name && type === this.metadata.type)
    }
  }
})
