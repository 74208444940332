
import { mapState, mapActions } from 'pinia'
import { corporaMixin } from '@/mixins'
import HeaderActions from '@/components/HeaderActions.vue'
import ElementPath from './ElementPath.vue'
import { useDisplayStore, useElementStore } from '@/stores'
import { defineComponent, PropType } from 'vue'
import { Element, ElementBase, ElementLight } from '@/types'
import { ElementNeighbor } from '@/api'

export default defineComponent({
  mixins: [
    corporaMixin
  ],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    }
  },
  components: {
    HeaderActions,
    ElementPath
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState(useElementStore, ['neighbors']),
    ...mapState(useDisplayStore, ['displayAllPaths']),
    elementNeighbors (): ElementNeighbor[] {
      return this.neighbors[this.element.id] ?? []
    },
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    sortedNeighbors () {
      if (!this.element?.id || !this.elementNeighbors?.length) return []

      const eltNeighbors = [...this.elementNeighbors]
      // If we know the parent folder, display paths containing that parent at the closest position
      if (eltNeighbors.length > 1 && this.fromFolderId) {
        eltNeighbors.sort(
          ({ path }) => {
            /*
             * Sort paths depending on the parent's position: 0 if a direct parent, 1 if grandparent, Infinite if not found
             * As parents are listed from top to bottom, look for the parent index from the end of the array (closer to the element)
             */
            const index = path.findLastIndex((elt: ElementLight | null) => elt?.id === this.fromFolderId)
            return index === -1 ? Infinity : path.length - 1 - index
          }
        )
      }

      return eltNeighbors
    },
    morePaths () {
      return this.elementNeighbors.length - 1
    },
    fromFolderId () {
      // Query parameter allowing to order path corresponding to the navigation scheme
      return this.$route.query.from
    }
  },
  methods: {
    ...mapActions(useDisplayStore, ['toggleAllPaths']),
    ...mapActions(useElementStore, ['listNeighbors']),
    async load () {
      if (!this.element || this.elementNeighbors.length) return
      this.loading = true
      try {
        await this.listNeighbors(this.element.id)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      handler: 'load',
      immediate: true
    }
  }
})
