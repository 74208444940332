
import { PropType, defineComponent } from 'vue'
import { sortBy } from 'lodash'
import { mapState, mapActions } from 'pinia'

import { truncateMixin } from '@/mixins'
import { useWorkerStore, useEntityStore } from '@/stores'

import Actions from './Actions.vue'
import Box from './Box.vue'
import EditionForm from './EditionForm.vue'

import { Element, ElementBase } from '@/types'
import { Transcription } from '@/types/transcription'
import { hasWorkerRun } from '@/helpers'

export default defineComponent({
  mixins: [
    truncateMixin
  ],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true
    }
  },
  components: {
    Actions,
    Box,
    EditionForm
  },
  data: () => ({
    workerRunFilter: '',
    editing: false
  }),
  mounted () {
    if (!this.inTranscription[this.transcription.id]?.results) this.listInTranscription(this.transcription.id)
  },
  computed: {
    ...mapState(useEntityStore, ['inTranscription']),
    /**
     * Values and display names for the options of the entity worker runs filter.
     * Array of IDs and display names.
     */
    entityFilterValues (): [string, string][] {
      const transcriptionEntities = this.inTranscription[this.transcription.id]?.results
      if (!transcriptionEntities) return []

      const values: [string, string][] = []
      // If there are TranscriptionEntities with no worker run, add the manual option as the very first item
      if (transcriptionEntities.some(transcriptionEntity => !hasWorkerRun(transcriptionEntity))) {
        values.push(['manual', 'Manual'])
      }

      values.push(
        ...sortBy(
          // Turn into an object to make the array unique by ID, but turn back into an array to allow sorting
          Object.entries(Object.fromEntries(
            transcriptionEntities
              .filter(hasWorkerRun)
              .map(transcriptionEntity => ([transcriptionEntity.worker_run.id, transcriptionEntity.worker_run.summary]))
          )),
          [1, 0]
        )
      )

      return values
    }
  },
  methods: {
    ...mapActions(useWorkerStore, ['getWorkerVersion']),
    ...mapActions(useEntityStore, ['listInTranscription'])
  },
  watch: {
    entityFilterValues: {
      handler (newValue) {
        // Automatically select the first available filter option
        if (newValue.length) this.workerRunFilter = newValue[0][0]
      },
      immediate: true
    }
  }
})
