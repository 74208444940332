import { defineStore } from 'pinia'

import { useNotificationStore } from '.'
import { UUID } from '@/types'
import { MetaData } from '@/types/metadata'
import {
  createMetadata,
  deleteMetadata,
  listMetadata,
  MetaDataCreate,
  MetadataUpdate,
  updateMetadata
} from '@/api'
import { errorParser } from '@/helpers'

interface State {
  metadata: {
    [elementId: UUID]: MetaData[]
  }
}

export const useMetaDataStore = defineStore('metadata', {
  state: (): State => ({
    metadata: {}
  }),
  actions: {
    async create (elementId: UUID, data: MetaDataCreate) {
      try {
        const resp = await createMetadata(elementId, data)
        if (this.metadata[elementId]) {
          this.metadata[elementId].push(resp)
        } else {
          this.metadata[elementId] = [resp]
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async update (elementId: UUID, metadata: MetadataUpdate & { id: UUID }) {
      try {
        if (!this.metadata[elementId]) return
        const resp = await updateMetadata(metadata.id, metadata)
        const elementMetadata = this.metadata[elementId]
        const index = elementMetadata.findIndex(md => md.id === resp.id)
        if (index < 0) throw new Error(`Metadata ${metadata.id} not found on element ${elementId}`)
        elementMetadata.splice(index, 1, resp)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async delete (elementId: UUID, metadataId: UUID) {
      try {
        const elementMetadata = this.metadata[elementId]
        const index = elementMetadata.findIndex(md => md.id === metadataId)
        if (index < 0) throw new Error(`Metadata ${metadataId} not found on element ${elementId}`)

        await deleteMetadata(metadataId)

        elementMetadata.splice(index, 1)
        this.metadata[elementId] = elementMetadata
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async list (elementId: UUID) {
      try {
        this.metadata[elementId] = await listMetadata(elementId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    }
  }
})
