import { TEXT_ORIENTATIONS } from '@/config'

import { useClassificationStore, useElementStore } from '@/stores'
import { defineStore } from 'pinia'
import { ElementBase, Element, TextOrientation, UUID } from '@/types'
import { ElementCreate } from '@/api'

const DEFAULT_TEXT_ORIENTATION = 'horizontal-lr'

const TOOL_NAMES = ['rectangle', 'polygon', 'select', 'deletion', 'median', 'type-edit'] as const
export type ToolName = typeof TOOL_NAMES[number]

const DEFAULT_TOOL: ToolName = 'select'

type SelectedElement = Omit<Element | ElementBase, 'id'> & { id: UUID | 'selected-polygon' }

type AnnotationCreate =
  Partial<ElementCreate> &
  Required<Pick<ElementCreate, 'corpus' | 'parent' | 'polygon'>> &
  { classId?: UUID | null }

interface State {
  /**
   * The currently selected text orientation in the transcription modal/sidebar.
   */
  textOrientation: TextOrientation

  /**
   * Whether or not the annotation editor is turned on.
   */
  enabled: boolean

  /**
   * The currently selected tool in the annotation editor.
   *
   * This is set to the default tool, `select`, when the editor is turned off.
   */
  tool: ToolName

  /**
   * Whether the user enabled batch creation.
   *
   * When this is turned off, the store module will not try to guess
   * any of the element's values from the store state and will instead
   * require more arguments.
   */
  batchCreation: boolean

  /**
   * Whether the user enabled batch deletion (no confirmation modal).
   */
  batchDeletion: boolean

  /**
   * Whether the user enabled batch type edition (no edition modal).
   */
  batchTypeEdition: boolean

  /**
   * Default type slugs by corpus ID, used when the batch creation mode is enabled.
   */
  defaultType: { [corpusId: UUID]: string }

  /**
   * Default ML class IDs by corpus ID, used when the batch creation mode is enabled.
   * This can be null to indicate that no class should be selected.
   * @type {[corpusId: string]: string?}
   */
  defaultClass: { [corpusId: UUID]: string | null | undefined }

  /**
   * Element IDs that are visible in the InteractiveImage, grouped by parent element ID.
   */
  visible: { [parentId: UUID]: Set<UUID> }

  /**
   * The currently hovered element ID.
   *
   * This allows a user to hover the element in the InteractiveImage
   * and see it highlighted in the children tree, and vice-versa.
   */
  hoveredId: string | null

  /**
   * Represents a copy of an element that has been selected (from the children tree or the interactive image).
   * If `enabled` is set, this object is intended to be a temporary and editable copy of
   * the element that does not reflect the real backend data until it is saved.
   *
   * During the creation of a new element (that does not exist in the backend) this object is also used and
   * its `id` property is set to `created-polygon`.
   *
   * Always prefer to compare this object by its `id` key instead of using references.
   */
  selectedElement: SelectedElement | null
}

export const useAnnotationStore = defineStore('annotation', {
  state: (): State => ({
    textOrientation: DEFAULT_TEXT_ORIENTATION,
    enabled: false,
    tool: DEFAULT_TOOL,
    batchCreation: false,
    batchDeletion: false,
    batchTypeEdition: false,
    defaultType: {},
    defaultClass: {},
    visible: {},
    hoveredId: null,
    selectedElement: null
  }),

  actions: {
    /**
     * Select a text orientation in the transcription modal/sidebar.
     */
    setTextOrientation (value: TextOrientation) {
      if (!(value in TEXT_ORIENTATIONS)) throw new Error(`Unknown text orientation ${value}`)
      this.textOrientation = value
    },

    /**
     * Turn the annotation mode on or off.
     * When the annotation mode is turned off, the selected tool is set to the default.
     * @param value On or off state. When null, the current state will be toggled.
     */
    toggle (value: boolean | null = null) {
      if (value === null) this.enabled = !this.enabled
      else this.enabled = Boolean(value)
      // Reset the tool to the default when turned off
      if (!this.enabled) {
        this.tool = DEFAULT_TOOL
        if (this.selectedElement?.id === 'created-polygon') {
          // Do not keep an unsaved drawn polygon
          this.selectedElement = null
        }
        this.batchDeletion = false
      }
    },

    /**
     * Select a tool in the annotation editor.
     * @param name Tool name found in the `TOOL_NAMES` array.
     */
    setTool (name: ToolName) {
      if (!TOOL_NAMES.includes(name)) throw new Error(`Unknown tool ${name}`)
      this.tool = name
      if (name !== 'deletion' && this.batchDeletion === true) this.batchDeletion = false
    },

    /**
     * Turn the batch creation mode on or off.
     * This determines whether or not the create action will allow optional parameters.
     * @param value On or off state.
     */
    setBatchCreation (value: boolean | null = null) {
      if (value === null) this.batchCreation = !this.batchCreation
      else this.batchCreation = Boolean(value)
    },

    /**
     * Turn the batch deletion mode on or off.
     * @param value On or off state.
     */
    setBatchDeletion (value: boolean | null = null) {
      if (value === null) this.batchDeletion = !this.batchDeletion
      else this.batchDeletion = Boolean(value)
    },

    /**
     * Turn the batch type edition mode on or off.
     * @param value On or off state.
     */
    setBatchTypeEdition (value: boolean | null = null) {
      if (value === null) this.batchTypeEdition = !this.batchTypeEdition
      else this.batchTypeEdition = Boolean(value)
    },

    /**
     * Show or hide an element.
     * If `visible` is set to true, the element zone will be visible in the InteractiveImage.
     */
    setVisible (parentId: UUID, id: UUID, visible = true) {
      if (parentId in this.visible) {
        if (visible) this.visible[parentId].add(id)
        else this.visible[parentId].delete(id)
      } else if (visible) {
        this.visible[parentId] = new Set([id])
      }
    },

    setVisibleBulk (parentId: UUID, ids: Iterable<UUID>, visible = true) {
      for (const id of ids) {
        this.setVisible(parentId, id, visible)
      }
    },

    cleanVisible (parentId: UUID) {
      // Remove visible attributes of an element children
      delete this.visible[parentId]
    },

    /**
     * Create an annotation: an element with a polygon and an optional classification on a parent element.
     *
     * @param param0
     *   Annotation parameters.
     *
     *   When `batchCreation` is enabled, the `name`, `type` and `classId` attributes can be auto-filled
     *   by this action from the `defaultType` and `defaultClass` states, as well as fetching
     *   the next available integer from the element list as a name.
     *
     *   When `batchCreation` is disabled, all attributes are required.
     */
    async create ({ corpus, name, type, classId, parent, polygon, ...payload }: AnnotationCreate) {
      if (!this.enabled) throw new Error('Cannot create annotations when the annotation mode is disabled.')

      // Fail for required arguments
      if (!corpus || !parent || !polygon) {
        throw new Error('The corpus ID, parent ID and polygon are required to create an annotation.')
      }

      // In batch creation mode, guess the type, classId and name.
      if (this.batchCreation) {
        if (!type) {
          type = this.defaultType[corpus]
          // Fail if there is no default type set
          if (!type) throw new Error(`No default type has been set for batch annotation on corpus ${corpus}.`)
        }

        // No error on the classId, since it can be optional
        if (!classId) classId = this.defaultClass[corpus]

        /*
         * Guess the name using a getter.
         * The element creation modal might reuse the default name as a default even in non-batch mode,
         * so this is in a separate getter.
         */
        if (!name) name = this.defaultName(parent, type)

        // Deselect the element to give feedback to the user, allowing them to start editing a new one with the InteractiveImage
        this.selectedElement = null
      } else if (!name || !type || classId === undefined) {
        /*
         * Fail if we are not annotating in batch and all the arguments are not set.
         * This is an extra safety measure that makes sure both the modal and panel call this action properly.
         * The classId may be null, so we check explicitly for `undefined`.
         */
        throw new Error('The name, type and classId are required when not annotating in batch.')
      }

      // There is no error handling here because both elementStore.create and classificationStore.create will show error notifications
      const element = await useElementStore().create({
        corpus,
        name,
        type,
        polygon,
        parent,
        ...payload
      })

      // Make sure the created element is visible in the editor
      this.setVisible(parent, element.id)

      // Create the optional classification
      if (classId) {
        await useClassificationStore().create(element.id, classId)
      }

      return element
    },

    async typeEdit (element: ElementBase | Element, type: string | null = null) {
      if (!this.enabled) throw new Error('Cannot edit element types when the annotation mode is disabled.')
      if (this.batchTypeEdition && element.corpus && this.defaultType[element.corpus.id]) {
        type = this.defaultType[element.corpus.id]
        if (type === element.type) return
      } else if (!type) { throw new Error("A valid type value is required to update the element's type.") }
      return useElementStore().patch(element.id, { type })
    }
  },
  getters: {
    /**
     * Guess the default name to use for a new annotation, given a parent ID and type slug:
     * count the currently known children of the parent element with the annotation's type and add 1.
     * This would yield "line 1", "line 2", "paragraph 1", etc.
     * Note that this might not work well if all the children are not loaded in the children tree.
     */
    defaultName: () => (parentId: UUID, type: string): string => {
      const elementStore = useElementStore()
      const childrenIds = elementStore.links[parentId]?.children ?? []
      return (childrenIds.filter(childId => elementStore.elements[childId]?.type === type).length + 1).toString()
    },

    /**
     * Whether or not the batch annotation mode is enabled and available for a particular corpus.
     * The batch mode can be enabled without setting a correct default type, so "availability" here
     * defines whether or not an annotation can be truly created in batch mode.
     * If batch mode is unavailable, an error will be thrown by the create action.
     */
    batchCreateAvailable: state => (corpusId: UUID): boolean => state.batchCreation && !!state.defaultType[corpusId] && !!state.defaultClass[corpusId] !== undefined
  }
})
