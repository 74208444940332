
import { MONTHS } from '@/config'
import { InterpretedDate } from '@/types/metadata'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    dates: {
      type: Array as PropType<InterpretedDate[]>,
      required: true
    },
    rawDate: {
      type: String,
      required: true
    }
  },
  computed: {
    upper () {
      return this.dates.find(d => d.type === 'upper')
    },
    lower () {
      return this.dates.find(d => d.type === 'lower')
    }
  },
  methods: {
    display (date: InterpretedDate): string {
      return `${date.year}${date.month || date.day ? ', ' : ''}${date.month ? MONTHS[date.month - 1] : ''}${date.day ? ' ' + date.day : ''}`
    }
  }
})
