
import { defineComponent, PropType } from 'vue'
import MarkdownIt from 'markdown-it'

import { UUID } from '@/types'
import { MetaData } from '@/types/metadata'

import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import MetadataActions from './Actions.vue'

/**
 * Displays a single metadata of a Markdown type.
 */
export default defineComponent({
  components: {
    MetadataActions,
    WorkerRunSummary
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
      validator: (value: unknown): boolean => value !== null && typeof value === 'object' && 'type' in value && value.type === 'markdown'
    }
  },
  computed: {
    html (): string {
      if (typeof this.metadata.value !== 'string') throw new Error('Unsupported metadata value')
      return MarkdownIt({ html: true }).render(this.metadata.value)
    }
  }
})
