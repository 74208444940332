import axios from 'axios'
import { unique } from '.'
import { UUID } from '@/types'
import { TypedMetaData, MetaData } from '@/types/metadata'

// CreateMetaData also allows a `worker_run_id`, but we don't add it here because the frontend shouldn't send WorkerRun IDs
export type MetaDataCreate = Omit<TypedMetaData, 'id'>

export const createMetadata = unique(
  async (elementId: UUID, data: MetaDataCreate): Promise<MetaData> =>
    (await axios.post(`/element/${elementId}/metadata/`, data)).data
)

export type MetadataUpdate = Partial<MetaDataCreate>

export const updateMetadata = unique(
  async (id: UUID, data: MetadataUpdate): Promise<MetaData> =>
    (await axios.patch(`/metadata/${id}/`, data)).data
)

export const deleteMetadata = unique(async (id: UUID) => await axios.delete(`/metadata/${id}/`))

export const listMetadata = unique(
  async (id: UUID): Promise<MetaData[]> => (await axios.get(`/element/${id}/metadata/`)).data
)
