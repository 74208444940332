
import { orderBy } from 'lodash'
import { mapActions, mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { isAxiosError } from 'axios'

import { ELEMENT_NAME_MAX_LENGTH } from '@/config'
import { errorParser } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'

import ElementImage from '@/components/Image/ElementImage.vue'
import Modal from '@/components/Modal.vue'
import { useAnnotationStore, useElementStore, useNotificationStore } from '@/stores'
import { UUID, Element, ElementBase } from '@/types'

export default defineComponent({
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    Modal
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true
    }
  },
  data: () => ({
    ELEMENT_NAME_MAX_LENGTH,
    updateLoading: false,
    deleteLoading: false,
    type: '',
    name: '',
    // API fields validation errors
    fieldErrors: {} as { name?: string[], type?: string[] }
  }),
  mounted () {
    this.type = this.element.type
    this.name = this.element.name
  },
  computed: {
    ...mapStores(useAnnotationStore, useElementStore),
    loading () {
      return this.deleteLoading || this.updateLoading
    },
    corpusId (): UUID | undefined {
      // Corpus ID for corporaMixin
      return this.element.corpus?.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    canUpdate () {
      // User has write access and type and name are valid and different from the element's current values
      return !this.loading && this.elementStore.canWrite(this.element.id) && (
        (this.type && this.type !== this.element.type) ||
        (this.name && this.name !== this.element.name)
      )
    },
    canUpdateTitle () {
      if (!this.elementStore.canWrite(this.element.id)) return 'A project write access is required to update this element'
      if (!this.canUpdate) return 'A valid updated type and name are required to update the element'
      return 'Update element'
    },
    canDelete () {
      return !this.loading && this.elementStore.canWrite(this.element.id)
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    nameFromElement () {
      // Update name input to default value if not defined
      if (!this.name) this.name = this.element.name
    },
    setErrors (error: unknown) {
      // Set field errors from API return value
      if (!error || typeof error !== 'object' || !('response' in error) || !isAxiosError(error)) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { name: [errorParser(error)] }
      else this.fieldErrors = error.response.data
    },
    async updateElement () {
      if (this.loading || !this.canUpdate || !this.elementStore.canWrite(this.element.id)) return
      this.updateLoading = true
      this.setErrors(null)
      this.nameFromElement()
      try {
        await this.elementStore.patch(this.element.id, {
          name: this.name,
          type: this.type
        })
        this.notify({ type: 'success', text: 'Element updated.' })
        this.$emit('update:modelValue', false)
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred while updating element: ${errorParser(e)}` })
      } finally {
        this.updateLoading = false
      }
    },
    async deleteElement () {
      if (!this.canDelete) return
      this.deleteLoading = true
      try {
        await this.elementStore.delete(this.element.id)
        this.annotationStore.selectedElement = null
        this.$emit('update:modelValue', false)
      } catch (e) {
        this.notify({ type: 'error', text: `An error occurred while deleting the element: ${errorParser(e)}` })
      } finally {
        this.deleteLoading = false
      }
    }
  },
  watch: {
    element: {
      immediate: true,
      handler (element) {
        if (!element.id) return
        /*
         * Do not retrieve the element again if it already exists in the store,
         * unless it lacks some of the attributes only available from RetrieveElement.
         * Some elements in the store can come from list endpoints such as those of the children tree.
         * This ensures there are no strange behaviors where some actions are only sometimes disabled when they shouldn't,
         * or some element attributes are not displayed at all.
         */
        if (!element.rights) this.elementStore.get(element.id)
      }
    }
  }
})
