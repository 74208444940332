import { assign } from 'lodash'
import { useElementStore } from '@/stores'

export const initialState = () => ({
  /*
   * Defines expanded nodes in the tree view
   * elementId[]
   */
  toggledNodes: [],
  /*
   * Stores tree element type filter during neighbors navigation i.e. with a same parent.
   * Filter is cleaned when updating navigation route in the `Element/Main.vue` component.
   */
  typeFilter: '',
  /**
   * Stores worker run UUID filter value
   */
  workerFilter: ''
})

export const mutations = {
  toggle (state, elementId) {
    const index = state.toggledNodes.indexOf(elementId)
    index === -1 ? state.toggledNodes.push(elementId) : state.toggledNodes.splice(index, 1)
    state.toggledNodes = [...state.toggledNodes]
  },

  setTypeFilter (state, value) {
    state.typeFilter = value
  },

  setWorkerFilter (state, value) {
    state.workerFilter = value
  },

  reset (state) {
    assign(state, initialState())
  }
}

// Map expanded nodes to elements
const getNodeChildren = (state, element) => {
  const elementStore = useElementStore()
  const link = elementStore.links[element.id]
  if (!link) return []
  return link.children.map(id => elementStore.elements[id]).filter(elt => elt)
}

// Recursively create the children tree for an element
const createTree = (state, getters, element, parent) => {
  // Automatically expand first node
  const expanded = (element.id === parent.id || (state.toggledNodes.includes(element.id)))
  // Recursively load expanded nodes
  const children = expanded ? getNodeChildren(state, element).map(elt => createTree(state, getters, elt, parent)) : []
  return { element, expanded, children }
}

export const getters = {
  tree: (state, getters) => element => { return createTree(state, getters, element, element) }
}

export const actions = {
  // Automatically expand/shrink a node from a children tree
  async toggleNode ({ state, commit }, { elementId }) {
    commit('toggle', elementId)
    const elementStore = useElementStore()
    /*
     * Do not fetch automatically if the item gets closed or if the pagination was incomplete
     * Do, however, fetch if the pagination was completed to allow the If-Modified-Since check
     */
    const expanded = state.toggledNodes.includes(elementId)
    const page = elementStore.childrenPagination[elementId]
    if (!expanded || (page && page.next)) return
    await elementStore.nextChildren(elementId, true)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
