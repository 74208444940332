import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-136c2332"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "is-narrow is-vcentered" }
const _hoisted_2 = {
  key: 0,
  colspan: "2"
}
const _hoisted_3 = { class: "shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditionForm = _resolveComponent("EditionForm")!
  const _component_Actions = _resolveComponent("Actions")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("th", _hoisted_1, _toDisplayString(_ctx.index), 1),
    (_ctx.editing)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
          _createVNode(_component_EditionForm, {
            class: "mb-2",
            element: _ctx.element,
            transcription: _ctx.transcription,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editing = false))
          }, null, 8, ["element", "transcription"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", null, [
            _createElementVNode("div", {
              class: "has-line-breaks",
              style: _normalizeStyle(_ctx.orientationStyle(_ctx.transcription.orientation))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.transcription.text), 1)
            ], 4)
          ]),
          _createElementVNode("td", _hoisted_3, [
            _createVNode(_component_Actions, {
              class: "are-medium",
              element: _ctx.element,
              transcription: _ctx.transcription,
              onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editing = true))
            }, null, 8, ["element", "transcription"])
          ])
        ], 64))
  ]))
}