import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2564ddc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "has-shadow" }
const _hoisted_2 = { class: "elt-header is-flex" }
const _hoisted_3 = {
  key: 0,
  class: "columns mx-0 is-align-items-center"
}
const _hoisted_4 = { class: "column p-0 is-narrow is-hidden-mobile" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "tag is-link" }
const _hoisted_7 = {
  key: 1,
  class: "loader py-2"
}
const _hoisted_8 = {
  key: 0,
  class: "px-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementPath = _resolveComponent("ElementPath")!
  const _component_HeaderActions = _resolveComponent("HeaderActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.element)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.morePaths > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    title: _ctx.morePaths + ' more path' + (_ctx.morePaths > 1 ? 's' : '') + ' for this element'
                  }, [
                    _createElementVNode("a", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAllPaths(null))),
                      class: "navbar-link"
                    }, [
                      _createElementVNode("div", _hoisted_6, " +" + _toDisplayString(_ctx.morePaths), 1)
                    ])
                  ], 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_ElementPath, {
              class: "column",
              element: _ctx.element,
              neighbor: _ctx.sortedNeighbors[0],
              loading: _ctx.loading,
              "keyboard-shortcuts": ""
            }, null, 8, ["element", "neighbor", "loading"]),
            (_ctx.corpusId)
              ? (_openBlock(), _createBlock(_component_HeaderActions, {
                  key: 0,
                  "corpus-id": _ctx.corpusId,
                  "element-id": _ctx.element.id
                }, null, 8, ["corpus-id", "element-id"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_7))
    ]),
    _createVNode(_Transition, { name: "paths" }, {
      default: _withCtx(() => [
        (_ctx.element && _ctx.displayAllPaths)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedNeighbors.slice(1), (neighbor, index) => {
                return (_openBlock(), _createBlock(_component_ElementPath, {
                  key: index,
                  element: _ctx.element,
                  neighbor: neighbor
                }, null, 8, ["element", "neighbor"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}