
import { isAxiosError } from 'axios'
import { uniqueId } from 'lodash'
import { mapStores } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { mapGetters as mapVuexGetters } from 'vuex'

import { MetaDataCreate } from '@/api'
import { MetaData } from '@/types/metadata'
import { UUID } from '@/types'
import { useAllowedMetaDataStore, useDisplayStore, useMetaDataStore, useNotificationStore } from '@/stores'

import Modal from '@/components/Modal.vue'
import MetadataForm, { FormErrors } from './Form.vue'
import { errorParser } from '@/helpers'

/**
 * A modal to create a new metadata or update an existing one
 */
export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean'
  },
  components: {
    MetadataForm,
    Modal
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    corpusId: {
      type: String as PropType<UUID>,
      required: true
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true
    },
    /**
     * An existing MetaData to edit. When not set, this creates a metadata instead.
     */
    metadata: {
      type: Object as PropType<MetaData | null>,
      default: null
    }
  },
  data: () => ({
    freeEdit: false,
    loading: false,
    formData: {
      name: '',
      type: 'text',
      value: ''
    } as MetaDataCreate,
    formValid: false,
    formErrors: {} as FormErrors,
    /**
     * Value for the ID attribute on the MetadataForm's `<form>` element.
     * This is used to allow buttons in the modal footer, which are outside of the form, to submit the form.
     */
    formId: `metadataform-${uniqueId()}`
  }),
  computed: {
    ...mapVuexGetters('auth', ['isAdmin']),
    ...mapStores(
      useAllowedMetaDataStore,
      useDisplayStore,
      useMetaDataStore,
      useNotificationStore
    ),
    corpusAllowedMetadata () {
      return this.allowedMetadataStore.allowedMetadata[this.corpusId] ?? []
    }
  },
  methods: {
    async initialize (metadata: MetaData | null) {
      // Ensure allowed metadata are loaded
      if (!(this.corpusId in this.allowedMetadataStore.allowedMetadata)) {
        this.loading = true
        try {
          await this.allowedMetadataStore.listAllowedMetadata(this.corpusId)
        } catch (err) {
          // Notify of the failure, but continue as if we actually had 0 allowed metadata
          this.notificationStore.notify({ type: 'error', text: errorParser(err) })
        } finally {
          this.loading = false
        }
      }

      if (metadata) {
        this.formData = {
          name: metadata.name,
          type: metadata.type,
          value: metadata.value
        }
        // When editing a metadata, enable free edit when the user is an admin and this metadata is not allowed
        this.freeEdit = this.isAdmin && !this.corpusAllowedMetadata.some(({ name, type }) => name === metadata.name && type === metadata.type)
      } else {
        this.formData = {
          name: this.displayStore.lastMetadataName ?? '',
          type: this.displayStore.lastMetadataType ?? 'text',
          value: this.displayStore.lastMetadataType === 'numeric' ? 0 : ''
        }
        // When creating a metadata, only enable free edit when the user is an admin and there are no allowed metadata at all
        this.freeEdit = this.isAdmin && !this.corpusAllowedMetadata.length
      }
    },
    submit () {
      if (this.loading || !this.formValid) return
      this.loading = true
      try {
        if (this.metadata !== null) this.metadataStore.update(this.elementId, { ...this.formData, id: this.metadata.id })
        else this.metadataStore.create(this.elementId, this.formData)

        this.displayStore.setLastMetadata(this.formData.name, this.formData.type)
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400) this.formErrors = err.response.data
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newValue: boolean) {
        if (newValue) this.initialize(this.metadata)
      }
    },
    metadata: {
      handler: 'initialize'
    }
  }
})
